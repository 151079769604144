import React from 'react';
import RequiredFieldMarker from './RequiredFieldMarker';

interface DateInputProps {
  value: string;
  onChange: (value: string) => void;
  error?: string;
  required?: boolean;
  label: string;
  maxDate?: string;
}

export default function DateInput({
  value,
  onChange,
  error,
  required = false,
  label,
  maxDate
}: DateInputProps) {
  return (
    <div>
      <label className="block text-sm font-medium text-gray-700">
        {label}
        {required && <RequiredFieldMarker />}
      </label>
      <input
        type="date"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        max={maxDate}
        className={`mt-1 block w-full rounded-md border-2 ${
          error ? 'border-red-300' : 'border-gray-300'
        } shadow-sm focus:border-warm-olive focus:ring-warm-olive`}
        required={required}
      />
      {error && (
        <p className="mt-1 text-sm text-red-600">{error}</p>
      )}
    </div>
  );
}