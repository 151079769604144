import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Plus } from 'lucide-react';
import { useStore } from '../../store/useStore';
import { ROUTES } from '../../routes/constants';
import WishlistCard from './wishlists/WishlistCard';
import EmptyState from './wishlists/EmptyState';

export default function WishlistsList() {
  const navigate = useNavigate();
  const { wishlists = [] } = useStore();

  const handleCreateWishlist = () => {
    navigate(ROUTES.CREATE_WISHLIST);
  };

  return (
    <div>
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-bold text-gray-900">Mijn Verlanglijsten</h2>
        <button
          onClick={handleCreateWishlist}
          className="bg-warm-olive text-white px-4 py-2 rounded-md hover:bg-cool-olive flex items-center"
        >
          <Plus className="h-5 w-5 mr-2" />
          Nieuwe Lijst
        </button>
      </div>

      <div className="space-y-4">
        {Array.isArray(wishlists) && wishlists.map((wishlist) => (
          <WishlistCard 
            key={wishlist.id} 
            wishlist={wishlist}
          />
        ))}

        {(!Array.isArray(wishlists) || wishlists.length === 0) && (
          <EmptyState />
        )}
      </div>
    </div>
  );
}