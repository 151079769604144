import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Gift } from 'lucide-react';
import { ROUTES } from '../routes/constants';

interface WishlistsSectionProps {
  participants: Array<{
    id: string;
    firstName: string;
    lastName: string;
    wishlistId?: string;
  }>;
  currentUserId: string;
  eventId: string;
  onWishlistSelect: (participantId: string) => void;
}

export default function WishlistsSection({ 
  participants, 
  currentUserId, 
  eventId,
  onWishlistSelect
}: WishlistsSectionProps) {
  const navigate = useNavigate();

  const handleWishlistAction = (participantId: string, wishlistId?: string) => {
    if (participantId === currentUserId) {
      onWishlistSelect(participantId);
    } else if (wishlistId) {
      navigate(`/wishlist/${wishlistId}`);
    } else {
      navigate(`/event/${eventId}/request-wishlist/${participantId}`);
    }
  };

  return (
    <div className="bg-white rounded-lg shadow-md p-3">
      <h2 className="text-xl font-semibold text-gray-900 mb-2">
        Wish2Share-Lijstjes
      </h2>

      <div className="space-y-1">
        {participants.map((participant) => (
          <div 
            key={`participant-${participant.id}`}
            className="bg-gray-50 rounded-lg hover:bg-gray-100 transition-colors"
          >
            <div className="p-2 grid grid-cols-[1fr,auto] gap-2 items-center">
              <div className="min-w-0">
                <p className="font-medium text-gray-900 truncate text-sm">
                  {participant.firstName} {participant.lastName}
                  {participant.id === currentUserId && ' (Jij)'}
                </p>
              </div>
              
              <button
                onClick={() => handleWishlistAction(participant.id, participant.wishlistId)}
                className="inline-flex items-center space-x-1 text-warm-olive hover:text-cool-olive whitespace-nowrap text-sm"
              >
                <Gift className="h-4 w-4 flex-shrink-0" />
                <span>
                  {participant.id === currentUserId ? (
                    participant.wishlistId ? 'Bekijk mijn lijst' : 'Kies lijst'
                  ) : (
                    participant.wishlistId ? 'Bekijk lijst' : 'Vraag lijst'
                  )}
                </span>
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}