import React from 'react';
import FilterButton from '../FilterButton';

interface PriceFiltersProps {
  selectedFilters: string[];
  onFilterToggle: (filter: string) => void;
}

export const priceRanges = [
  { label: '€15 - €25', value: 'price-15-25' },
  { label: '€25 - €50', value: 'price-25-50' },
  { label: '€50 - €100', value: 'price-50-100' }
];

export default function PriceFilters({ selectedFilters, onFilterToggle }: PriceFiltersProps) {
  return (
    <div className="flex flex-wrap gap-2">
      {priceRanges.map(range => (
        <FilterButton
          key={range.value}
          label={range.label}
          value={range.value}
          isSelected={selectedFilters.includes(range.value)}
          onClick={() => onFilterToggle(range.value)}
        />
      ))}
    </div>
  );
}