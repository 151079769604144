import React, { useState } from 'react';
import { Calendar, MapPin, Clock, Info, Phone, Mail, Edit2 } from 'lucide-react';
import CountdownTimer from './CountdownTimer';
import EventDetailsForm from './event/EventDetailsForm';
import { EventDetailsData } from '../types/event';

interface EventDetailsProps {
  name: string;
  date: string;
  time?: string;
  endTime?: string;
  budget: number;
  location?: string;
  theme?: string;
  additionalInfo?: string;
  organizerPhone?: string;
  organizerEmail?: string;
  isOrganizer: boolean;
  onUpdate: (data: Partial<EventDetailsData>) => Promise<void>;
}

export default function EventDetails({ 
  name,
  date,
  time,
  endTime,
  budget,
  location,
  theme,
  additionalInfo,
  organizerPhone,
  organizerEmail,
  isOrganizer,
  onUpdate
}: EventDetailsProps) {
  const [isEditing, setIsEditing] = useState(false);

  const handleSave = async (data: EventDetailsData) => {
    try {
      await onUpdate(data);
      setIsEditing(false);
    } catch (error) {
      console.error('Error updating event details:', error);
    }
  };

  if (isEditing) {
    return (
      <div className="bg-white rounded-lg shadow-md p-3">
        <EventDetailsForm
          initialData={{
            name,
            date,
            time,
            endTime,
            location,
            theme,
            additionalInfo,
            organizerPhone,
            organizerEmail
          }}
          onSave={handleSave}
          onCancel={() => setIsEditing(false)}
        />
      </div>
    );
  }

  return (
    <div className="bg-white rounded-lg shadow-md p-3">
      <div className="flex justify-between items-start">
        <h1 className="text-xl font-bold text-gray-900">{name}</h1>
        {isOrganizer && (
          <button
            onClick={() => setIsEditing(true)}
            className="p-1 text-gray-400 hover:text-gray-600 transition-colors"
            title="Bewerk evenement"
          >
            <Edit2 className="h-5 w-5" />
          </button>
        )}
      </div>

      <div className="-mt-1">
        <CountdownTimer targetDate={date} targetTime={time} />
      </div>
      
      <div className="mt-2 space-y-1.5">
        <div className="flex items-center text-gray-600">
          <Calendar className="h-4 w-4 mr-2 flex-shrink-0" />
          <span className="text-sm">
            {new Date(date).toLocaleDateString('nl-NL', { 
              weekday: 'long',
              day: 'numeric',
              month: 'long',
              year: 'numeric'
            })}
            {time && (
              <>
                <span className="mx-1">van</span>
                {time}
                {endTime && (
                  <>
                    <span className="mx-1">tot</span>
                    {endTime}
                  </>
                )}
              </>
            )}
          </span>
        </div>

        {location && (
          <div className="flex items-center">
            <MapPin className="h-4 w-4 mr-2 flex-shrink-0 text-gray-600" />
            <span className="text-sm">
              <a
                href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(location)}`}
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-600 hover:text-warm-olive inline"
              >
                {location}
              </a>
            </span>
          </div>
        )}

        {theme && (
          <div className="flex items-center text-gray-600">
            <Clock className="h-4 w-4 mr-2 flex-shrink-0" />
            <span className="text-sm">{theme}</span>
          </div>
        )}

        {additionalInfo && (
          <div className="flex items-start text-gray-600">
            <Info className="h-4 w-4 mr-2 flex-shrink-0 mt-0.5" />
            <p className="whitespace-pre-wrap text-sm">{additionalInfo}</p>
          </div>
        )}

        {(organizerPhone || organizerEmail) && (
          <div className="mt-2 pt-2 border-t border-gray-200">
            <h3 className="text-xs font-bold text-gray-700 mb-1">Contactgegevens organisator:</h3>
            <div className="space-y-1">
              {organizerPhone && (
                <div className="flex items-center">
                  <Phone className="h-4 w-4 mr-2 flex-shrink-0 text-gray-600" />
                  <span className="text-sm">
                    <a 
                      href={`tel:${organizerPhone}`} 
                      className="text-gray-600 hover:text-warm-olive inline"
                    >
                      {organizerPhone}
                    </a>
                  </span>
                </div>
              )}
              {organizerEmail && (
                <div className="flex items-center">
                  <Mail className="h-4 w-4 mr-2 flex-shrink-0 text-gray-600" />
                  <span className="text-sm">
                    <a 
                      href={`mailto:${organizerEmail}`} 
                      className="text-gray-600 hover:text-warm-olive inline"
                    >
                      {organizerEmail}
                    </a>
                  </span>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}