import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../config/firebase';
import { useStore } from '../store/useStore';
import { Gift, Edit2, Save, Trash2, Plus, X } from 'lucide-react';
import { toast } from 'react-toastify';
import UserAvatar from '../components/UserAvatar';
import { WishlistItem } from '../types/wishlist';

export default function WishlistDetailPage() {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { currentUser } = useStore();
  const [wishlist, setWishlist] = useState<any>(null);
  const [owner, setOwner] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [editingItem, setEditingItem] = useState<string | null>(null);
  const [editedItem, setEditedItem] = useState<WishlistItem>({
    id: '',
    title: '',
    description: '',
    url: '',
    price: undefined
  });
  const [showAddForm, setShowAddForm] = useState(false);
  const [newItem, setNewItem] = useState<Omit<WishlistItem, 'id'>>({
    title: '',
    description: '',
    url: '',
    price: undefined
  });

  useEffect(() => {
    const fetchWishlist = async () => {
      if (!id) return;
      
      try {
        const wishlistDoc = await getDoc(doc(db, 'wishlists', id));
        if (wishlistDoc.exists()) {
          const wishlistData = { id: wishlistDoc.id, ...wishlistDoc.data() };
          setWishlist(wishlistData);

          // Fetch owner details
          const ownerDoc = await getDoc(doc(db, 'users', wishlistData.userId));
          if (ownerDoc.exists()) {
            setOwner(ownerDoc.data());
          }
        }
      } catch (error) {
        console.error('Error fetching wishlist:', error);
        toast.error('Er ging iets mis bij het ophalen van de verlanglijst');
      } finally {
        setLoading(false);
      }
    };

    fetchWishlist();
  }, [id]);

  const isOwner = currentUser?.id === wishlist?.userId;

  const handleEditItem = (item: WishlistItem) => {
    setEditingItem(item.id);
    setEditedItem(item);
  };

  const handleSaveItem = async (itemId: string) => {
    try {
      const updatedItems = wishlist.items.map((item: WishlistItem) =>
        item.id === itemId ? editedItem : item
      );

      await updateDoc(doc(db, 'wishlists', wishlist.id), {
        items: updatedItems
      });

      setWishlist({ ...wishlist, items: updatedItems });
      setEditingItem(null);
      toast.success('Item bijgewerkt');
    } catch (error) {
      toast.error('Er ging iets mis bij het bijwerken van het item');
    }
  };

  const handleDeleteItem = async (itemId: string) => {
    if (!window.confirm('Weet je zeker dat je dit item wilt verwijderen?')) return;

    try {
      const updatedItems = wishlist.items.filter((item: WishlistItem) => item.id !== itemId);
      await updateDoc(doc(db, 'wishlists', wishlist.id), {
        items: updatedItems
      });

      setWishlist({ ...wishlist, items: updatedItems });
      toast.success('Item verwijderd');
    } catch (error) {
      toast.error('Er ging iets mis bij het verwijderen van het item');
    }
  };

  const handleAddItem = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!newItem.title) {
      toast.error('Titel is verplicht');
      return;
    }

    try {
      const newItemWithId = {
        ...newItem,
        id: crypto.randomUUID()
      };

      const updatedItems = [...(wishlist.items || []), newItemWithId];
      await updateDoc(doc(db, 'wishlists', wishlist.id), {
        items: updatedItems
      });

      setWishlist({ ...wishlist, items: updatedItems });
      setNewItem({ title: '', description: '', url: '', price: undefined });
      setShowAddForm(false);
      toast.success('Item toegevoegd');
    } catch (error) {
      toast.error('Er ging iets mis bij het toevoegen van het item');
    }
  };

  if (loading) {
    return (
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="bg-white rounded-lg shadow-sm p-6">
          <p className="text-center text-gray-600">Laden...</p>
        </div>
      </div>
    );
  }

  if (!wishlist) {
    return (
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="bg-white rounded-lg shadow-sm p-6 text-center">
          <p className="text-gray-600">Verlanglijst niet gevonden.</p>
          <button
            onClick={() => navigate('/')}
            className="mt-4 text-warm-olive hover:text-cool-olive"
          >
            Terug naar Home
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <div className="bg-white rounded-lg shadow-sm">
        <div className="p-6 border-b border-gray-200">
          <div className="flex items-center space-x-4">
            {owner && (
              <UserAvatar
                firstName={owner.firstName}
                lastName={owner.lastName}
                photoURL={owner.photoURL}
                size="lg"
              />
            )}
            <div>
              <h1 className="text-2xl font-bold text-gray-900">{wishlist.name}</h1>
              {owner && (
                <p className="text-gray-600">
                  Verlanglijst van {owner.firstName} {owner.lastName}
                </p>
              )}
            </div>
          </div>
        </div>

        <div className="p-6">
          <div className="space-y-6">
            {wishlist.items?.map((item: WishlistItem) => (
              <div key={item.id} className="p-4 bg-gray-50 rounded-md">
                {editingItem === item.id ? (
                  <div className="space-y-4">
                    <input
                      type="text"
                      value={editedItem.title}
                      onChange={(e) => setEditedItem({ ...editedItem, title: e.target.value })}
                      className="block w-full rounded-md border-2 border-gray-300 shadow-sm focus:border-warm-olive focus:ring-warm-olive"
                      placeholder="Titel"
                    />
                    <input
                      type="text"
                      value={editedItem.description || ''}
                      onChange={(e) => setEditedItem({ ...editedItem, description: e.target.value })}
                      className="block w-full rounded-md border-2 border-gray-300 shadow-sm focus:border-warm-olive focus:ring-warm-olive"
                      placeholder="Beschrijving (optioneel)"
                    />
                    <input
                      type="url"
                      value={editedItem.url || ''}
                      onChange={(e) => setEditedItem({ ...editedItem, url: e.target.value })}
                      className="block w-full rounded-md border-2 border-gray-300 shadow-sm focus:border-warm-olive focus:ring-warm-olive"
                      placeholder="URL (optioneel)"
                    />
                    <input
                      type="number"
                      value={editedItem.price || ''}
                      onChange={(e) => setEditedItem({ ...editedItem, price: parseFloat(e.target.value) || undefined })}
                      className="block w-full rounded-md border-2 border-gray-300 shadow-sm focus:border-warm-olive focus:ring-warm-olive"
                      placeholder="Prijs (optioneel)"
                      step="0.01"
                    />
                    <div className="flex justify-end space-x-2">
                      <button
                        onClick={() => handleSaveItem(item.id)}
                        className="p-2 text-green-600 hover:text-green-800"
                      >
                        <Save className="h-5 w-5" />
                      </button>
                      <button
                        onClick={() => setEditingItem(null)}
                        className="p-2 text-gray-400 hover:text-gray-600"
                      >
                        <X className="h-5 w-5" />
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className="flex justify-between items-start">
                    <div className="flex-grow">
                      <h3 className="font-medium text-lg">{item.title}</h3>
                      {item.description && (
                        <p className="text-gray-600 mt-1">{item.description}</p>
                      )}
                      {item.url && (
                        <a
                          href={item.url}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-warm-olive hover:text-cool-olive mt-2 block"
                        >
                          Bekijk online
                        </a>
                      )}
                      {item.price && (
                        <p className="text-gray-600 mt-2">€{item.price.toFixed(2)}</p>
                      )}
                    </div>
                    <div className="flex space-x-2">
                      {isOwner ? (
                        <>
                          <button
                            onClick={() => handleEditItem(item)}
                            className="p-2 text-gray-400 hover:text-gray-600"
                          >
                            <Edit2 className="h-5 w-5" />
                          </button>
                          <button
                            onClick={() => handleDeleteItem(item.id)}
                            className="p-2 text-red-400 hover:text-red-600"
                          >
                            <Trash2 className="h-5 w-5" />
                          </button>
                        </>
                      ) : (
                        <button
                          onClick={() => {}}
                          className="bg-warm-olive text-white px-4 py-2 rounded-md hover:bg-cool-olive flex items-center"
                        >
                          <Gift className="h-5 w-5 mr-2" />
                          Koop dit cadeau
                        </button>
                      )}
                    </div>
                  </div>
                )}
              </div>
            ))}

            {wishlist.items?.length === 0 && (
              <p className="text-center text-gray-500">
                Deze verlanglijst is nog leeg.
              </p>
            )}

            {isOwner && !showAddForm && (
              <button
                onClick={() => setShowAddForm(true)}
                className="w-full px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50 flex items-center justify-center"
              >
                <Plus className="h-5 w-5 mr-2" />
                Item Toevoegen
              </button>
            )}

            {isOwner && showAddForm && (
              <form onSubmit={handleAddItem} className="space-y-4 p-4 bg-gray-50 rounded-md">
                <input
                  type="text"
                  value={newItem.title}
                  onChange={(e) => setNewItem({ ...newItem, title: e.target.value })}
                  className="block w-full rounded-md border-2 border-gray-300 shadow-sm focus:border-warm-olive focus:ring-warm-olive"
                  placeholder="Titel"
                  required
                />
                <input
                  type="text"
                  value={newItem.description}
                  onChange={(e) => setNewItem({ ...newItem, description: e.target.value })}
                  className="block w-full rounded-md border-2 border-gray-300 shadow-sm focus:border-warm-olive focus:ring-warm-olive"
                  placeholder="Beschrijving (optioneel)"
                />
                <input
                  type="url"
                  value={newItem.url}
                  onChange={(e) => setNewItem({ ...newItem, url: e.target.value })}
                  className="block w-full rounded-md border-2 border-gray-300 shadow-sm focus:border-warm-olive focus:ring-warm-olive"
                  placeholder="URL (optioneel)"
                />
                <input
                  type="number"
                  value={newItem.price || ''}
                  onChange={(e) => setNewItem({ ...newItem, price: parseFloat(e.target.value) || undefined })}
                  className="block w-full rounded-md border-2 border-gray-300 shadow-sm focus:border-warm-olive focus:ring-warm-olive"
                  placeholder="Prijs (optioneel)"
                  step="0.01"
                />
                <div className="flex justify-end space-x-3">
                  <button
                    type="button"
                    onClick={() => setShowAddForm(false)}
                    className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50"
                  >
                    Annuleren
                  </button>
                  <button
                    type="submit"
                    className="px-4 py-2 bg-warm-olive text-white rounded-md hover:bg-cool-olive"
                  >
                    Toevoegen
                  </button>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}