import { 
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  sendEmailVerification,
  sendPasswordResetEmail,
  updateProfile,
  updatePassword,
  EmailAuthProvider,
  reauthenticateWithCredential,
  User,
  setPersistence,
  browserLocalPersistence,
  browserSessionPersistence
} from 'firebase/auth';
import { auth } from '../config/firebase';
import { databaseService } from './database';

export interface AuthPersistenceOptions {
  rememberMe: boolean;
}

export const authService = {
  async setPersistence(options: AuthPersistenceOptions) {
    const persistenceType = options.rememberMe ? browserLocalPersistence : browserSessionPersistence;
    await setPersistence(auth, persistenceType);
  },

  async register(email: string, password: string, firstName: string, lastName: string, birthdate: string) {
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    
    // Create user profile in Firestore
    await databaseService.create('users', userCredential.user.uid, {
      firstName,
      lastName,
      email,
      birthdate,
      firstName_lower: firstName.toLowerCase(),
      lastName_lower: lastName.toLowerCase(),
      notifications: {
        email: true
      }
    });

    await updateProfile(userCredential.user, {
      displayName: `${firstName} ${lastName}`
    });
    
    return userCredential.user;
  },

  async login(email: string, password: string, persistenceOptions: AuthPersistenceOptions) {
    await this.setPersistence(persistenceOptions);
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    return userCredential.user;
  },

  async logout() {
    await signOut(auth);
    localStorage.removeItem('authUser');
    sessionStorage.removeItem('authUser');
  },

  async resetPassword(email: string) {
    await sendPasswordResetEmail(auth, email);
  },

  async updatePassword(user: User, currentPassword: string, newPassword: string) {
    const credential = EmailAuthProvider.credential(user.email!, currentPassword);
    await reauthenticateWithCredential(user, credential);
    await updatePassword(user, newPassword);
  },

  getCurrentUser() {
    return auth.currentUser;
  },

  onAuthStateChanged(callback: (user: User | null) => void) {
    return auth.onAuthStateChanged(callback);
  }
};