import { create } from 'zustand';

export type NotificationType = 'success' | 'error' | 'info' | 'warning';

export interface Notification {
  id: string;
  type: NotificationType;
  message: string;
}

interface NotificationStore {
  notifications: Notification[];
  addNotification: (type: NotificationType, message: string) => void;
  removeNotification: (id: string) => void;
}

export const useNotificationStore = create<NotificationStore>((set) => ({
  notifications: [],
  addNotification: (type, message) => {
    const id = crypto.randomUUID();
    set((state) => ({
      notifications: [...state.notifications, { id, type, message }]
    }));
    // Auto-remove after 5 seconds
    setTimeout(() => {
      set((state) => ({
        notifications: state.notifications.filter((n) => n.id !== id)
      }));
    }, 5000);
  },
  removeNotification: (id) =>
    set((state) => ({
      notifications: state.notifications.filter((n) => n.id !== id)
    }))
}));

// Utility functions for adding notifications
export const showSuccess = (message: string) => {
  useNotificationStore.getState().addNotification('success', message);
};

export const showError = (message: string) => {
  useNotificationStore.getState().addNotification('error', message);
};

export const showInfo = (message: string) => {
  useNotificationStore.getState().addNotification('info', message);
};

export const showWarning = (message: string) => {
  useNotificationStore.getState().addNotification('warning', message);
};