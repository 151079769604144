import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useStore } from '../store/useStore';
import { Mail, Copy } from 'lucide-react';
import { toast } from 'react-toastify';
import InviteMethodCard from '../components/invites/InviteMethodCard';

export default function EventInvitesPage() {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { events, currentUser } = useStore();

  const event = events.find(e => e.id === id);
  if (!event || !currentUser) {
    navigate('/dashboard');
    return null;
  }

  const eventUrl = `${window.location.origin}/event/${event.id}/welcome`;
  const messageBody = `Hey allemaal!

Jullie zijn uitgenodigd voor ${event.name}!

Klik op onderstaande link om deel te nemen:
${eventUrl}

Met feestelijke groeten,
${currentUser.firstName}${event.registrationDeadline ? `

Registreren kan tot: ${new Date(event.registrationDeadline).toLocaleDateString('nl-NL', {
  weekday: 'long',
  day: 'numeric',
  month: 'long',
  year: 'numeric'
})}` : ''}`;

  const handleEmailShare = () => {
    const mailtoLink = `mailto:?subject=Uitnodiging voor ${event.name}&body=${encodeURIComponent(messageBody)}`;
    window.location.href = mailtoLink;
    handleComplete();
  };

  const handleWhatsAppShare = () => {
    const whatsappUrl = `https://wa.me/?text=${encodeURIComponent(messageBody)}`;
    window.open(whatsappUrl, '_blank');
    handleComplete();
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(messageBody);
    toast.success('Uitnodiging gekopieerd naar klembord');
    handleComplete();
  };

  const handleComplete = () => {
    navigate(`/event/${event.id}`);
  };

  return (
    <div className="max-w-2xl mx-auto px-4 py-8">
      <div className="bg-white rounded-lg shadow-sm p-6">
        <div className="mb-8">
          <h1 className="text-2xl font-bold text-gray-900 mb-2">
            Verstuur uitnodigingen
          </h1>
          <p className="text-gray-600">
            Deel de link met alle deelnemers voor {event.name}
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <InviteMethodCard
            icon={Mail}
            title="E-mail"
            onClick={handleEmailShare}
          />
          <InviteMethodCard
            icon="whatsapp"
            title="WhatsApp"
            onClick={handleWhatsAppShare}
          />
          <InviteMethodCard
            icon={Copy}
            title="Kopieer Link"
            onClick={handleCopyLink}
          />
        </div>

        <div className="mt-6 pt-6 border-t border-gray-200">
          <button
            onClick={handleComplete}
            className="w-full px-4 py-2 bg-warm-olive text-white rounded-md hover:bg-cool-olive"
          >
            Ga naar evenement
          </button>
        </div>
      </div>
    </div>
  );
}