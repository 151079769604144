import React, { useState } from 'react';
import { useStore } from '../store/useStore';
import { useRequireAuth } from '../hooks/useRequireAuth';
import EventsList from '../components/dashboard/EventsList';
import WishlistsList from '../components/dashboard/WishlistsList';
import EventEditModal from '../components/dashboard/EventEditModal';

export default function Dashboard() {
  const { currentUser } = useStore();
  const [editingEventId, setEditingEventId] = useState<string | null>(null);
  
  // Use the custom hook to handle auth
  const user = useRequireAuth();

  if (!user) {
    return null;
  }

  const handleEditEvent = (eventId: string) => {
    setEditingEventId(eventId);
  };

  const handleCloseEventEdit = () => {
    setEditingEventId(null);
  };

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
        <EventsList
          currentUserId={currentUser?.id || ''}
          onEditEvent={handleEditEvent}
        />
        <div className="space-y-8">
          <WishlistsList />
        </div>
      </div>

      {editingEventId && (
        <EventEditModal
          eventId={editingEventId}
          isOpen={true}
          onClose={handleCloseEventEdit}
        />
      )}
    </div>
  );
}