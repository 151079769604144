import React, { useState } from 'react';
import { Gift, User, Menu, X } from 'lucide-react';
import { Link } from 'react-router-dom';
import { useStore } from '../store/useStore';
import { useAuth } from './AuthContext';
import NotificationBadge from './NotificationBadge';

export default function Navbar() {
  const { currentUser, logout, events } = useStore();
  const { showLoginModal, showRegisterModal } = useAuth();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const getTotalUnreadMessages = () => {
    if (!currentUser || !events) return 0;
    return events.reduce((total, event) => {
      const lastReadTimestamp = event.lastReadTimestamps?.[currentUser.id] || 0;
      const unreadMessages = (event.messages || []).filter(
        msg => new Date(msg.timestamp).getTime() > lastReadTimestamp && msg.userId !== currentUser.id
      ).length;
      return total + unreadMessages;
    }, 0);
  };

  return (
    <nav className="bg-white shadow-sm">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16 items-center">
          <Link to="/" className="flex items-center logo-container">
            <Gift className="h-8 w-8 text-warm-olive" />
            <span className="ml-2 text-xl font-bold text-gray-900">wish2share.com</span>
          </Link>

          {/* Desktop Navigation */}
          <div className="hidden md:flex items-center space-x-4">
            {currentUser && (
              <>
                <Link
                  to="/dashboard"
                  className="text-gray-600 hover:text-gray-900 px-3 py-2 rounded-md text-sm font-medium flex items-center"
                >
                  Dashboard
                  {getTotalUnreadMessages() > 0 && (
                    <NotificationBadge 
                      count={getTotalUnreadMessages()} 
                      className="ml-1" 
                    />
                  )}
                </Link>
                <Link
                  to="/profile"
                  className="text-gray-600 hover:text-gray-900 px-3 py-2 rounded-md text-sm font-medium flex items-center"
                >
                  <User className="h-5 w-5 mr-1" />
                  Profiel
                </Link>
                <button
                  onClick={logout}
                  className="bg-warm-olive text-white px-4 py-2 rounded-md text-sm font-medium hover:bg-cool-olive"
                >
                  Uitloggen
                </button>
              </>
            )}
            {!currentUser && (
              <>
                <button
                  onClick={() => showLoginModal()}
                  className="bg-warm-olive text-white px-4 py-2 rounded-md text-sm font-medium hover:bg-cool-olive"
                >
                  Inloggen
                </button>
                <button
                  onClick={() => showRegisterModal()}
                  className="bg-white text-warm-olive border border-warm-olive px-4 py-2 rounded-md text-sm font-medium hover:bg-warm-beige"
                >
                  Registreren
                </button>
              </>
            )}
          </div>

          {/* Mobile menu button */}
          <div className="md:hidden">
            <button
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="text-gray-600 hover:text-gray-900 p-2"
            >
              {isMenuOpen ? (
                <X className="h-6 w-6" />
              ) : (
                <Menu className="h-6 w-6" />
              )}
            </button>
          </div>
        </div>
      </div>

      {/* Mobile Navigation */}
      {isMenuOpen && (
        <div className="md:hidden">
          <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
            {currentUser ? (
              <>
                <Link
                  to="/dashboard"
                  className="text-gray-600 hover:text-gray-900 block px-3 py-2 rounded-md text-base font-medium"
                  onClick={() => setIsMenuOpen(false)}
                >
                  <div className="flex items-center">
                    Dashboard
                    {getTotalUnreadMessages() > 0 && (
                      <NotificationBadge 
                        count={getTotalUnreadMessages()} 
                        className="ml-2" 
                      />
                    )}
                  </div>
                </Link>
                <Link
                  to="/profile"
                  className="text-gray-600 hover:text-gray-900 block px-3 py-2 rounded-md text-base font-medium"
                  onClick={() => setIsMenuOpen(false)}
                >
                  <div className="flex items-center">
                    <User className="h-5 w-5 mr-2" />
                    Profiel
                  </div>
                </Link>
                <button
                  onClick={() => {
                    logout();
                    setIsMenuOpen(false);
                  }}
                  className="w-full text-left bg-warm-olive text-white px-3 py-2 rounded-md text-base font-medium hover:bg-cool-olive mt-2"
                >
                  Uitloggen
                </button>
              </>
            ) : (
              <>
                <button
                  onClick={() => {
                    showLoginModal();
                    setIsMenuOpen(false);
                  }}
                  className="w-full text-left bg-warm-olive text-white px-3 py-2 rounded-md text-base font-medium hover:bg-cool-olive"
                >
                  Inloggen
                </button>
                <button
                  onClick={() => {
                    showRegisterModal();
                    setIsMenuOpen(false);
                  }}
                  className="w-full text-left bg-white text-warm-olive border border-warm-olive px-3 py-2 rounded-md text-base font-medium hover:bg-warm-beige mt-2"
                >
                  Registreren
                </button>
              </>
            )}
          </div>
        </div>
      )}
    </nav>
  );
}