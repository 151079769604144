import React from 'react';
import { Plus, X } from 'lucide-react';
import { EventForm } from '../../types/eventForm';
import { useStore } from '../../store/useStore';

interface ParticipantFormProps {
  eventData: EventForm;
  onUpdate: (data: Partial<EventForm>) => void;
  onBack: () => void;
  onSubmit: () => void;
}

export default function ParticipantForm({ eventData, onUpdate, onBack, onSubmit }: ParticipantFormProps) {
  const { currentUser } = useStore();

  // Show organizer as first participant if not already added
  React.useEffect(() => {
    if (currentUser && !eventData.participants.some(p => p.id === currentUser.id)) {
      onUpdate({
        participants: [
          {
            id: currentUser.id,
            firstName: currentUser.firstName,
            lastName: currentUser.lastName,
            confirmed: true
          },
          ...eventData.participants
        ]
      });
    }
  }, [currentUser, eventData.participants, onUpdate]);

  const handleAddParticipant = () => {
    onUpdate({
      participants: [
        ...eventData.participants,
        {
          id: crypto.randomUUID(),
          firstName: '',
          lastName: '',
          confirmed: false
        }
      ]
    });
  };

  const handleRemoveParticipant = (index: number) => {
    // Prevent removing the organizer (first participant)
    if (index === 0 && eventData.participants[0].id === currentUser?.id) {
      return;
    }
    const newParticipants = eventData.participants.filter((_, i) => i !== index);
    onUpdate({ participants: newParticipants });
  };

  const updateParticipant = (index: number, field: string, value: string) => {
    const newParticipants = [...eventData.participants];
    newParticipants[index] = { ...newParticipants[index], [field]: value };
    onUpdate({ participants: newParticipants });
  };

  return (
    <div className="space-y-6">
      <h2 className="text-lg font-medium text-gray-900">Deelnemers Toevoegen</h2>
      
      <div className="space-y-4">
        {eventData.participants.map((participant, index) => {
          const isOrganizer = index === 0 && participant.id === currentUser?.id;

          return (
            <div key={participant.id} className="flex items-center space-x-4">
              <div className="flex-grow grid grid-cols-2 gap-4">
                <input
                  type="text"
                  placeholder="Voornaam"
                  value={participant.firstName}
                  onChange={(e) => updateParticipant(index, 'firstName', e.target.value)}
                  className={`block w-full rounded-md border-2 ${
                    isOrganizer 
                      ? 'border-warm-olive bg-warm-olive/5 text-gray-500'
                      : 'border-gray-300'
                  } shadow-sm focus:border-warm-olive focus:ring-warm-olive`}
                  required
                />
                <input
                  type="text"
                  placeholder="Achternaam"
                  value={participant.lastName}
                  onChange={(e) => updateParticipant(index, 'lastName', e.target.value)}
                  className={`block w-full rounded-md border-2 ${
                    isOrganizer 
                      ? 'border-warm-olive bg-warm-olive/5 text-gray-500'
                      : 'border-gray-300'
                  } shadow-sm focus:border-warm-olive focus:ring-warm-olive`}
                  required
                />
              </div>
              {!isOrganizer && (
                <button
                  type="button"
                  onClick={() => handleRemoveParticipant(index)}
                  className="text-red-600 hover:text-red-800"
                >
                  <X className="h-5 w-5" />
                </button>
              )}
            </div>
          );
        })}
      </div>

      <button
        type="button"
        onClick={handleAddParticipant}
        className="w-full border border-gray-300 text-gray-700 px-4 py-2 rounded-md hover:bg-gray-50 flex items-center justify-center"
      >
        <Plus className="h-5 w-5 mr-2" />
        Deelnemer Toevoegen
      </button>

      <div className="flex justify-end space-x-3 pt-6">
        <button
          type="button"
          onClick={onBack}
          className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50"
        >
          Terug
        </button>
        <button
          type="button"
          onClick={onSubmit}
          className="px-4 py-2 bg-warm-olive text-white rounded-md hover:bg-cool-olive"
        >
          Evenement Aanmaken
        </button>
      </div>
    </div>
  );
}