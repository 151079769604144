import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStore } from '../store/useStore';
import { toast } from 'react-toastify';
import { useAuth } from '../components/AuthContext';
import EventBasicForm from '../components/event/EventBasicForm';
import ParticipantTypeForm from '../components/event/ParticipantTypeForm';
import ParticipantForm from '../components/event/ParticipantForm';
import { EventForm } from '../types/eventForm';

export default function CreateEventPage() {
  const navigate = useNavigate();
  const { createEvent, currentUser } = useStore();
  const { showLoginModal } = useAuth();
  
  const [step, setStep] = useState(1);
  const [eventData, setEventData] = useState<EventForm>({
    name: '',
    date: '',
    time: '',
    budget: undefined,
    isLootjesEvent: false,
    participantType: 'manual',
    registrationDeadline: '',
    maxParticipants: undefined,
    participants: []
  });

  const handleUpdateEventData = (data: Partial<EventForm>) => {
    setEventData(prev => ({ ...prev, ...data }));
  };

  const validateStep1 = () => {
    if (!eventData.name || !eventData.date) {
      toast.error('Vul alle verplichte velden in');
      return false;
    }

    if (eventData.registrationDeadline) {
      const eventDateTime = new Date(`${eventData.date}T${eventData.time || '00:00'}`);
      const deadlineDateTime = new Date(eventData.registrationDeadline);
      
      if (deadlineDateTime > eventDateTime) {
        toast.error('De deadline voor registratie moet vóór de datum van het evenement liggen');
        return false;
      }
    }

    return true;
  };

  const handleNextStep = () => {
    if (!validateStep1()) return;

    if (!currentUser) {
      showLoginModal(() => setStep(2));
      return;
    }

    setStep(2);
  };

  const handleCreateEvent = async () => {
    if (eventData.participantType === 'manual' && eventData.participants.length < 3 && eventData.isLootjesEvent) {
      toast.error('Voeg minimaal 3 deelnemers toe voor een lootjes evenement');
      return;
    }

    try {
      const organizerParticipant = {
        id: currentUser!.id,
        firstName: currentUser!.firstName,
        lastName: currentUser!.lastName,
        confirmed: true
      };

      const participants = eventData.participants.some(p => p.id === currentUser!.id)
        ? eventData.participants
        : [organizerParticipant, ...eventData.participants];

      const eventId = await createEvent({
        ...eventData,
        participants
      });

      if (eventData.isLootjesEvent && eventData.participantType === 'manual' && participants.length > 1) {
        navigate(`/event/${eventId}/invites`);
      } else {
        navigate(`/event/${eventId}`);
      }

      toast.success('Evenement aangemaakt!');
    } catch (error) {
      console.error('Create event error:', error);
      toast.error('Er ging iets mis bij het aanmaken van het evenement');
    }
  };

  return (
    <div className="max-w-2xl mx-auto px-4 py-8">
      <div className="bg-white rounded-lg shadow-sm p-6">
        <h1 className="text-2xl font-bold text-gray-900 mb-6">Nieuw Evenement</h1>

        {step === 1 ? (
          <form onSubmit={(e) => { e.preventDefault(); handleNextStep(); }} className="space-y-6">
            <EventBasicForm eventData={eventData} onUpdate={handleUpdateEventData} />
            <ParticipantTypeForm eventData={eventData} onUpdate={handleUpdateEventData} />
            <button
              type="submit"
              className="w-full bg-warm-olive text-white px-4 py-2 rounded-md hover:bg-cool-olive"
            >
              Volgende
            </button>
          </form>
        ) : (
          <ParticipantForm
            eventData={eventData}
            onUpdate={handleUpdateEventData}
            onBack={() => setStep(1)}
            onSubmit={handleCreateEvent}
          />
        )}
      </div>
    </div>
  );
}