import React, { useState } from 'react';
import { X, Plus } from 'lucide-react';
import { useStore } from '../store/useStore';
import { toast } from 'react-toastify';

interface WishlistLinkModalProps {
  isOpen: boolean;
  onClose: () => void;
  eventId: string;
  participantId: string;
}

export default function WishlistLinkModal({ isOpen, onClose, eventId, participantId }: WishlistLinkModalProps) {
  const { wishlists, updateEvent, events } = useStore();
  const [selectedWishlistId, setSelectedWishlistId] = useState<string>('');
  const [showNewWishlistForm, setShowNewWishlistForm] = useState(false);
  const [newWishlistName, setNewWishlistName] = useState('');

  if (!isOpen) return null;

  const event = events.find(e => e.id === eventId);
  if (!event) return null;

  const handleLinkWishlist = async () => {
    try {
      const updatedParticipants = {
        ...event.participants,
        [participantId]: {
          ...event.participants[participantId],
          wishlistId: selectedWishlistId
        }
      };

      await updateEvent(eventId, { participants: updatedParticipants });
      toast.success('Verlanglijst gekoppeld aan evenement');
      onClose();
    } catch (error) {
      toast.error('Er ging iets mis bij het koppelen van de verlanglijst');
    }
  };

  const handleCreateAndLinkWishlist = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!newWishlistName.trim()) {
      toast.error('Geef je verlanglijst een naam');
      return;
    }

    try {
      const { createWishlist } = useStore.getState();
      const wishlistId = await createWishlist({
        name: newWishlistName,
        items: [],
        isPrivate: false
      });

      const updatedParticipants = {
        ...event.participants,
        [participantId]: {
          ...event.participants[participantId],
          wishlistId
        }
      };

      await updateEvent(eventId, { participants: updatedParticipants });
      toast.success('Nieuwe verlanglijst aangemaakt en gekoppeld');
      onClose();
    } catch (error) {
      toast.error('Er ging iets mis bij het aanmaken van de verlanglijst');
    }
  };

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="flex min-h-full items-center justify-center p-4">
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75" onClick={onClose} />
        
        <div className="relative w-full max-w-md bg-white rounded-lg shadow-xl">
          <div className="p-6">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-semibold text-gray-900">
                {showNewWishlistForm ? 'Nieuwe Verlanglijst' : 'Kies een Verlanglijst'}
              </h2>
              <button onClick={onClose} className="text-gray-400 hover:text-gray-500">
                <X className="h-6 w-6" />
              </button>
            </div>

            {showNewWishlistForm ? (
              <form onSubmit={handleCreateAndLinkWishlist} className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Naam van de verlanglijst
                  </label>
                  <input
                    type="text"
                    value={newWishlistName}
                    onChange={(e) => setNewWishlistName(e.target.value)}
                    className="mt-1 block w-full rounded-md border-2 border-gray-300 shadow-sm focus:border-warm-olive focus:ring-warm-olive"
                    placeholder="Bijvoorbeeld: Verjaardag 2024"
                    required
                  />
                </div>
                <div className="flex justify-end space-x-3">
                  <button
                    type="button"
                    onClick={() => setShowNewWishlistForm(false)}
                    className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50"
                  >
                    Terug
                  </button>
                  <button
                    type="submit"
                    className="px-4 py-2 bg-warm-olive text-white rounded-md hover:bg-cool-olive"
                  >
                    Aanmaken en Koppelen
                  </button>
                </div>
              </form>
            ) : (
              <div className="space-y-4">
                {wishlists.length > 0 ? (
                  <>
                    <div className="space-y-2">
                      {wishlists.map((wishlist) => (
                        <button
                          key={wishlist.id}
                          onClick={() => setSelectedWishlistId(wishlist.id)}
                          className={`w-full flex items-center justify-between p-4 rounded-lg border-2 transition-colors ${
                            selectedWishlistId === wishlist.id
                              ? 'border-warm-olive bg-warm-olive/10'
                              : 'border-gray-200 hover:border-warm-olive'
                          }`}
                        >
                          <div className="text-left">
                            <h4 className="font-medium text-gray-900">{wishlist.name}</h4>
                            <p className="text-sm text-gray-500">{wishlist.items?.length || 0} items</p>
                          </div>
                          <div className={`w-4 h-4 rounded-full border-2 ${
                            selectedWishlistId === wishlist.id
                              ? 'border-warm-olive bg-warm-olive'
                              : 'border-gray-300'
                          }`} />
                        </button>
                      ))}
                    </div>

                    <button
                      onClick={() => setShowNewWishlistForm(true)}
                      className="w-full flex items-center justify-center space-x-2 p-4 rounded-lg border-2 border-dashed border-gray-300 hover:border-warm-olive text-gray-600 hover:text-warm-olive"
                    >
                      <Plus className="h-5 w-5" />
                      <span>Nieuwe verlanglijst maken</span>
                    </button>

                    <div className="flex justify-end space-x-3 mt-6">
                      <button
                        onClick={onClose}
                        className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50"
                      >
                        Annuleren
                      </button>
                      <button
                        onClick={handleLinkWishlist}
                        disabled={!selectedWishlistId}
                        className="px-4 py-2 bg-warm-olive text-white rounded-md hover:bg-cool-olive disabled:opacity-50"
                      >
                        Koppel Verlanglijst
                      </button>
                    </div>
                  </>
                ) : (
                  <div className="text-center py-8">
                    <p className="text-gray-600 mb-4">Je hebt nog geen verlanglijsten</p>
                    <button
                      onClick={() => setShowNewWishlistForm(true)}
                      className="px-4 py-2 bg-warm-olive text-white rounded-md hover:bg-cool-olive"
                    >
                      Maak een verlanglijst
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}