import React, { useState } from 'react';
import { Heart } from 'lucide-react';
import { AmazonProduct } from '../../types/amazon';

interface ProductCardProps {
  product: AmazonProduct;
  onSelect: (product: AmazonProduct, wishlistName: string) => Promise<void>;
}

export default function ProductCard({ product, onSelect }: ProductCardProps) {
  const [wishlistName, setWishlistName] = useState('');
  const [isAdding, setIsAdding] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!wishlistName.trim()) return;

    setIsAdding(true);
    try {
      await onSelect(product, wishlistName);
    } finally {
      setIsAdding(false);
    }
  };

  return (
    <div className="bg-white rounded-lg shadow-sm overflow-hidden hover:shadow-md transition-shadow">
      <div className="relative pb-[100%]">
        <img 
          src={product.image} 
          alt={product.title}
          className="absolute inset-0 w-full h-full object-contain p-4"
          loading="lazy"
        />
      </div>
      <div className="p-4">
        <h3 className="font-medium text-gray-900 mb-2 line-clamp-2 min-h-[3rem]">
          {product.title}
        </h3>
        <div className="flex items-center justify-between mb-3">
          <p className="text-warm-olive font-bold">
            {product.price}
          </p>
          {product.brand && (
            <span className="text-sm text-gray-500">
              {product.brand}
            </span>
          )}
        </div>
        
        <form onSubmit={handleSubmit} className="space-y-3">
          <input
            type="text"
            value={wishlistName}
            onChange={(e) => setWishlistName(e.target.value)}
            placeholder="Naam van je verlanglijst"
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:border-warm-olive focus:ring-warm-olive"
          />
          <button
            type="submit"
            disabled={isAdding || !wishlistName.trim()}
            className="w-full flex items-center justify-center space-x-2 bg-warm-olive text-white px-4 py-2 rounded-md hover:bg-cool-olive transition-colors disabled:opacity-50"
          >
            <Heart className="h-5 w-5" />
            <span>{isAdding ? 'Toevoegen...' : 'Toevoegen aan lijst'}</span>
          </button>
        </form>
      </div>
    </div>
  );
}