import React, { useState } from 'react';
import { Plus } from 'lucide-react';
import { DndContext, DragEndEvent, DragOverlay, useSensor, useSensors, PointerSensor } from '@dnd-kit/core';
import { restrictToWindowEdges } from '@dnd-kit/modifiers';
import { useStore } from '../../store/useStore';
import { toast } from 'react-toastify';
import TaskList from './TaskList';
import ParticipantList from './ParticipantList';
import DraggableParticipant from './DraggableParticipant';

interface PartyPrepsProps {
  eventId: string;
  isOrganizer: boolean;
  participants: Array<{
    id: string;
    firstName: string;
    lastName: string;
  }>;
  currentUserId: string;
}

export default function PartyPrepsSection({ eventId, isOrganizer, participants, currentUserId }: PartyPrepsProps) {
  const [showAddTask, setShowAddTask] = useState(false);
  const [newTaskTitle, setNewTaskTitle] = useState('');
  const [activeId, setActiveId] = useState<string | null>(null);
  const { updateEvent, events } = useStore();
  const event = events.find(e => e.id === eventId);
  const tasks = event?.tasks || [];

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    })
  );

  const handleAddTask = async () => {
    if (!newTaskTitle.trim()) {
      toast.error('Geef de taak een titel');
      return;
    }

    try {
      const newTask = {
        id: crypto.randomUUID(),
        title: newTaskTitle,
        completed: false,
        assignedParticipants: []
      };

      await updateEvent(eventId, {
        tasks: [...tasks, newTask]
      });

      setNewTaskTitle('');
      setShowAddTask(false);
      toast.success('Taak toegevoegd');
    } catch (error) {
      toast.error('Er ging iets mis bij het toevoegen van de taak');
    }
  };

  const handleDragStart = (event: DragEndEvent) => {
    setActiveId(event.active.id as string);
  };

  const handleDragEnd = async (event: DragEndEvent) => {
    setActiveId(null);
    const { active, over } = event;
    if (!over) return;

    const participantId = active.id as string;
    const taskId = over.id as string;

    try {
      const updatedTasks = tasks.map(task => {
        if (task.id === taskId) {
          const isAlreadyAssigned = task.assignedParticipants.includes(participantId);
          if (isAlreadyAssigned) {
            toast.info('Je bent al toegewezen aan deze taak');
            return task;
          }
          return {
            ...task,
            assignedParticipants: [...task.assignedParticipants, participantId]
          };
        }
        return task;
      });

      await updateEvent(eventId, { tasks: updatedTasks });
      toast.success('Je bent toegewezen aan de taak');
    } catch (error) {
      toast.error('Er ging iets mis bij het toewijzen van de taak');
    }
  };

  const handleToggleTask = async (taskId: string) => {
    try {
      const updatedTasks = tasks.map(task =>
        task.id === taskId ? { ...task, completed: !task.completed } : task
      );
      await updateEvent(eventId, { tasks: updatedTasks });
      toast.success('Taakstatus bijgewerkt');
    } catch (error) {
      toast.error('Er ging iets mis bij het bijwerken van de taak');
    }
  };

  const handleDeleteTask = async (taskId: string) => {
    try {
      const updatedTasks = tasks.filter(task => task.id !== taskId);
      await updateEvent(eventId, { tasks: updatedTasks });
      toast.success('Taak verwijderd');
    } catch (error) {
      toast.error('Er ging iets mis bij het verwijderen van de taak');
    }
  };

  const handleRemoveParticipant = async (taskId: string, participantId: string) => {
    try {
      const updatedTasks = tasks.map(task => {
        if (task.id === taskId) {
          return {
            ...task,
            assignedParticipants: task.assignedParticipants.filter(id => id !== participantId)
          };
        }
        return task;
      });

      await updateEvent(eventId, { tasks: updatedTasks });
      toast.success('Je bent verwijderd van de taak');
    } catch (error) {
      toast.error('Er ging iets mis bij het verwijderen van de deelnemer');
    }
  };

  const activeParticipant = participants.find(p => p.id === activeId);
  const currentParticipant = participants.find(p => p.id === currentUserId);

  return (
    <div className="bg-white rounded-lg shadow-md p-4">
      <div className="flex items-start justify-between mb-3">
        <div className="flex-grow">
          <h2 className="text-xl font-semibold text-gray-900">PartyPreps</h2>
          <p className="text-sm text-gray-500">
            Sleep je naam naar één of meerdere taken om jezelf toe te wijzen
          </p>
        </div>
        {!isOrganizer && currentParticipant && (
          <div className="flex items-center space-x-2 ml-4">
            <span className="text-sm text-gray-500">Jouw naam:</span>
            <DraggableParticipant
              participant={currentParticipant}
              isCurrentUser={true}
            />
          </div>
        )}
      </div>

      <DndContext
        sensors={sensors}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        modifiers={[restrictToWindowEdges]}
      >
        <div className="space-y-4">
          {isOrganizer && !showAddTask && (
            <button
              onClick={() => setShowAddTask(true)}
              className="w-full flex items-center justify-center space-x-2 p-3 text-warm-olive hover:bg-warm-olive/5 border-2 border-dashed border-warm-olive/30 rounded-lg transition-colors"
            >
              <Plus className="h-5 w-5" />
              <span>Voeg taak toe</span>
            </button>
          )}

          {showAddTask && (
            <div className="space-y-3 bg-gray-50 p-4 rounded-lg">
              <input
                type="text"
                value={newTaskTitle}
                onChange={(e) => setNewTaskTitle(e.target.value)}
                placeholder="Beschrijf de taak..."
                className="w-full rounded-md border-2 border-gray-300 shadow-sm focus:border-warm-olive focus:ring-warm-olive p-2"
                autoFocus
              />
              <div className="flex justify-end space-x-2">
                <button
                  onClick={() => setShowAddTask(false)}
                  className="px-3 py-1.5 text-gray-600 hover:text-gray-800"
                >
                  Annuleren
                </button>
                <button
                  onClick={handleAddTask}
                  className="px-4 py-1.5 bg-warm-olive text-white rounded-md hover:bg-cool-olive"
                >
                  Taak Toevoegen
                </button>
              </div>
            </div>
          )}

          <TaskList
            tasks={tasks}
            participants={participants}
            currentUserId={currentUserId}
            isOrganizer={isOrganizer}
            onToggleTask={handleToggleTask}
            onDeleteTask={isOrganizer ? handleDeleteTask : undefined}
            onRemoveParticipant={handleRemoveParticipant}
          />

          {isOrganizer && (
            <div className="mt-4 pt-4 border-t border-gray-200">
              <h3 className="text-sm font-medium text-gray-700 mb-3">
                Alle deelnemers:
              </h3>
              <ParticipantList
                participants={participants}
                currentUserId={currentUserId}
              />
            </div>
          )}
        </div>

        <DragOverlay>
          {activeParticipant && (
            <DraggableParticipant
              participant={activeParticipant}
              isCurrentUser={activeParticipant.id === currentUserId || isOrganizer}
            />
          )}
        </DragOverlay>
      </DndContext>
    </div>
  );
}