import { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStore } from '../store/useStore';
import { useNotification } from './useNotification';
import { amazonService } from '../services/amazon';
import { AmazonProduct } from '../types/amazon';

interface UseProductSearchProps {
  onError?: (message: string) => void;
}

export function useProductSearch({ onError }: UseProductSearchProps = {}) {
  const navigate = useNavigate();
  const { createWishlist } = useStore();
  const { showSuccess } = useNotification();
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedFilters, setSelectedFilters] = useState<string[]>([]);
  const [products, setProducts] = useState<AmazonProduct[]>([]);
  const [loading, setLoading] = useState(false);

  const handleSearch = useCallback(async () => {
    if (!searchTerm.trim()) {
      onError?.('Voer een zoekterm in');
      return;
    }
    
    setLoading(true);
    try {
      const priceFilter = selectedFilters
        .find(f => f.startsWith('price-'))
        ?.split('-')
        .slice(1);

      const categoryFilter = selectedFilters
        .find(f => !f.startsWith('price-'));

      const results = await amazonService.searchProducts({
        searchTerm: searchTerm.trim(),
        category: categoryFilter,
        minPrice: priceFilter?.[0],
        maxPrice: priceFilter?.[1]
      });

      setProducts(results);
    } catch (error) {
      if (error instanceof Error) {
        onError?.(error.message);
      } else {
        onError?.('Er ging iets mis bij het zoeken van producten');
      }
    } finally {
      setLoading(false);
    }
  }, [searchTerm, selectedFilters, onError]);

  const handleFilterToggle = useCallback((filter: string) => {
    setSelectedFilters(prev => {
      if (filter.startsWith('price-')) {
        // Remove any existing price filters
        const withoutPrices = prev.filter(f => !f.startsWith('price-'));
        return prev.includes(filter) ? withoutPrices : [...withoutPrices, filter];
      } else {
        // Remove any existing category filters
        const withoutCategories = prev.filter(f => f.startsWith('price-'));
        return prev.includes(filter) ? withoutCategories : [...withoutCategories, filter];
      }
    });
  }, []);

  const handleAddToWishlist = useCallback(async (product: AmazonProduct, wishlistName: string) => {
    if (!wishlistName.trim()) {
      onError?.('Geef je verlanglijst een naam');
      return;
    }

    try {
      const wishlistId = await createWishlist({
        name: wishlistName.trim(),
        items: [{
          id: product.ASIN,
          title: product.title,
          description: `${product.brand} - ${product.category}`,
          url: product.affiliateUrl,
          price: parseFloat(product.price.replace(/[^0-9.-]+/g, "")) || null
        }],
        isPrivate: false
      });
      
      showSuccess('Product toegevoegd aan je verlanglijst!');
      navigate(`/wishlist/${wishlistId}`);
    } catch (error) {
      onError?.('Er ging iets mis bij het toevoegen van het product');
    }
  }, [createWishlist, navigate, showSuccess, onError]);

  return {
    searchTerm,
    setSearchTerm,
    selectedFilters,
    products,
    loading,
    handleSearch,
    handleFilterToggle,
    handleAddToWishlist
  };
}