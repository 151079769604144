import React, { useState } from 'react';
import { Gift } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import NameDrawingAnimation from './NameDrawingAnimation';

interface DrawnNameSectionProps {
  drawnName?: string;
  drawnParticipantId?: string;
  currentUserName: string;
  participants: Array<{ 
    id: string;
    firstName: string; 
    lastName: string;
    email?: string;
    wishlistId?: string;
  }>;
  onNameDrawn: (name: string) => void;
  eventId: string;
}

export default function DrawnNameSection({ 
  drawnName, 
  drawnParticipantId,
  currentUserName, 
  participants,
  onNameDrawn,
  eventId
}: DrawnNameSectionProps) {
  const [showDrawingModal, setShowDrawingModal] = useState(false);
  const navigate = useNavigate();

  const drawnParticipant = participants.find(p => p.id === drawnParticipantId);

  const handleWishlistAction = () => {
    if (!drawnParticipant) return;
    
    if (drawnParticipant.wishlistId) {
      navigate(`/wishlist/${drawnParticipant.wishlistId}`);
    } else {
      navigate(`/event/${eventId}/request-wishlist/${drawnParticipant.id}`);
    }
  };

  return (
    <div className="bg-white rounded-lg shadow-md p-4">
      <h2 className="text-xl font-semibold text-gray-900">Shhh! Jij koopt voor:</h2>
      
      {drawnName ? (
        <div className="flex items-center justify-between mt-2">
          <div className="text-lg font-medium text-warm-olive">
            {drawnName}
          </div>
          <button
            onClick={handleWishlistAction}
            className="inline-flex items-center space-x-2 text-warm-olive hover:text-cool-olive"
          >
            <Gift className="h-5 w-5 flex-shrink-0" />
            <span>
              {drawnParticipant?.wishlistId ? 'Bekijk verlanglijst' : 'Vraag verlanglijst'}
            </span>
          </button>
        </div>
      ) : (
        <button
          onClick={() => setShowDrawingModal(true)}
          className="w-full bg-warm-olive text-white px-4 py-2 mt-2 rounded-md hover:bg-cool-olive flex items-center justify-center transition-colors"
        >
          <Gift className="h-5 w-5 mr-2" />
          <span>Trek een naam</span>
        </button>
      )}

      <NameDrawingAnimation
        isOpen={showDrawingModal}
        onClose={() => setShowDrawingModal(false)}
        names={participants
          .filter(p => p.firstName + ' ' + p.lastName !== currentUserName)
          .map(p => `${p.firstName} ${p.lastName}`)}
        onNameDrawn={onNameDrawn}
      />
    </div>
  );
}