export const ROUTES = {
  HOME: '/',
  DASHBOARD: '/dashboard',
  PROFILE: '/profile',
  SEARCH: '/search',
  CREATE_WISHLIST: '/create-wishlist',
  WISHLIST: '/wishlist/:id',
  EVENT: '/event/:id',
  EVENT_WELCOME: '/event/:id/welcome',
  EVENT_INVITES: '/event/:id/invites',
  EVENT_REQUEST_WISHLIST: '/event/:eventId/request-wishlist/:participantId',
  CREATE_EVENT: '/create-event',
  PRIVACY: '/privacy',
  LOGO: '/logo'
} as const;

export const generatePath = {
  wishlist: (id: string) => `/wishlist/${id}`,
  event: (id: string) => `/event/${id}`,
  eventWelcome: (id: string) => `/event/${id}/welcome`,
  eventInvites: (id: string) => `/event/${id}/invites`,
  eventRequestWishlist: (eventId: string, participantId: string) => 
    `/event/${eventId}/request-wishlist/${participantId}`
};