import { useEffect, useRef } from 'react';

interface ScrollOptions {
  behavior?: ScrollBehavior;
  threshold?: number;
}

export function useScrollToBottom(
  dependencies: any[],
  options: ScrollOptions = { behavior: 'smooth', threshold: 100 }
) {
  const containerRef = useRef<HTMLDivElement>(null);
  const isNearBottomRef = useRef(true);

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    const checkIfNearBottom = () => {
      const { scrollTop, scrollHeight, clientHeight } = container;
      const distanceFromBottom = scrollHeight - scrollTop - clientHeight;
      isNearBottomRef.current = distanceFromBottom <= (options.threshold || 100);
    };

    const handleScroll = () => {
      checkIfNearBottom();
    };

    container.addEventListener('scroll', handleScroll);
    return () => container.removeEventListener('scroll', handleScroll);
  }, [options.threshold]);

  useEffect(() => {
    const container = containerRef.current;
    if (!container || !isNearBottomRef.current) return;

    const scrollToBottom = () => {
      container.scrollTo({
        top: container.scrollHeight,
        behavior: options.behavior
      });
    };

    scrollToBottom();
  }, [...dependencies]);

  return containerRef;
}