import React from 'react';
import { EventForm } from '../../types/eventForm';

interface ParticipantTypeFormProps {
  eventData: EventForm;
  onUpdate: (data: Partial<EventForm>) => void;
}

export default function ParticipantTypeForm({ eventData, onUpdate }: ParticipantTypeFormProps) {
  return (
    <div className="space-y-4">
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Hoe wil je deelnemers toevoegen?
        </label>
        <div className="space-y-2">
          <label className="flex items-center space-x-3">
            <input
              type="radio"
              checked={eventData.participantType === 'manual'}
              onChange={() => onUpdate({ participantType: 'manual' })}
              className="h-4 w-4 text-warm-olive focus:ring-warm-olive border-gray-300"
            />
            <span className="text-sm text-gray-700">Ik voeg zelf alle deelnemers toe</span>
          </label>
          <label className="flex items-center space-x-3">
            <input
              type="radio"
              checked={eventData.participantType === 'self-register'}
              onChange={() => onUpdate({ participantType: 'self-register' })}
              className="h-4 w-4 text-warm-olive focus:ring-warm-olive border-gray-300"
            />
            <span className="text-sm text-gray-700">Deelnemers registreren zichzelf via een link</span>
          </label>
        </div>
      </div>

      {eventData.participantType === 'self-register' && (
        <div>
          <label className="block text-sm font-medium text-gray-700">Maximum aantal deelnemers</label>
          <input
            type="number"
            value={eventData.maxParticipants || ''}
            onChange={(e) => onUpdate({ maxParticipants: e.target.value ? parseInt(e.target.value) : undefined })}
            className="mt-1 block w-full rounded-md border-2 border-gray-300 shadow-sm focus:border-warm-olive focus:ring-warm-olive"
            min="1"
          />
        </div>
      )}
    </div>
  );
}