import React from 'react';
import { Gift } from 'lucide-react';

interface LogoProps {
  size?: 'sm' | 'md' | 'lg' | 'xl';
  color?: string;
}

const sizes = {
  sm: 'h-6 w-6',
  md: 'h-8 w-8',
  lg: 'h-12 w-12',
  xl: 'h-16 w-16'
};

export default function Logo({ size = 'md', color = '#606C38' }: LogoProps) {
  const downloadLogo = (format: 'svg' | 'png') => {
    const svgElement = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    svgElement.setAttribute('width', '512');
    svgElement.setAttribute('height', '512');
    svgElement.setAttribute('viewBox', '0 0 24 24');
    svgElement.setAttribute('fill', 'none');
    svgElement.setAttribute('stroke', color);
    svgElement.setAttribute('stroke-width', '2');
    svgElement.setAttribute('stroke-linecap', 'round');
    svgElement.setAttribute('stroke-linejoin', 'round');

    const path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
    path.setAttribute('d', 'M20 12v10H4V12M22 7H2v5h20V7zM12 22V7M12 7H7.5a2.5 2.5 0 0 1 0-5C11 2 12 7 12 7zM12 7h4.5a2.5 2.5 0 0 0 0-5C13 2 12 7 12 7z');
    svgElement.appendChild(path);

    const svgData = new XMLSerializer().serializeToString(svgElement);
    const svgBlob = new Blob([svgData], { type: 'image/svg+xml;charset=utf-8' });

    if (format === 'svg') {
      const downloadLink = document.createElement('a');
      downloadLink.href = URL.createObjectURL(svgBlob);
      downloadLink.download = 'wish2share-logo.svg';
      downloadLink.click();
      URL.revokeObjectURL(downloadLink.href);
    } else if (format === 'png') {
      const canvas = document.createElement('canvas');
      canvas.width = 512;
      canvas.height = 512;
      const ctx = canvas.getContext('2d');
      
      if (ctx) {
        const img = new Image();
        img.onload = () => {
          ctx.drawImage(img, 0, 0);
          const downloadLink = document.createElement('a');
          downloadLink.href = canvas.toDataURL('image/png');
          downloadLink.download = 'wish2share-logo.png';
          downloadLink.click();
        };
        img.src = URL.createObjectURL(svgBlob);
      }
    }
  };

  return (
    <div className="inline-flex flex-col items-center">
      <Gift className={`${sizes[size]} text-warm-olive`} />
      <div className="mt-4 space-y-2">
        <button
          onClick={() => downloadLogo('svg')}
          className="block w-full px-4 py-2 text-sm text-warm-olive hover:bg-warm-olive hover:text-white border border-warm-olive rounded-md transition-colors"
        >
          Download SVG
        </button>
        <button
          onClick={() => downloadLogo('png')}
          className="block w-full px-4 py-2 text-sm text-warm-olive hover:bg-warm-olive hover:text-white border border-warm-olive rounded-md transition-colors"
        >
          Download PNG (512x512)
        </button>
      </div>
    </div>
  );
}