import React, { useState } from 'react';
import { Gift } from 'lucide-react';
import ShareWishlistModal from './ShareWishlistModal';

interface WishlistInviteHandlerProps {
  recipientFirstName?: string;
  recipientLastName?: string;
  recipientEmail?: string;
}

export default function WishlistInviteHandler({ 
  recipientFirstName,
  recipientLastName,
  recipientEmail 
}: WishlistInviteHandlerProps) {
  const [showShareModal, setShowShareModal] = useState(false);

  return (
    <div className="text-center py-8">
      <Gift className="h-12 w-12 text-gray-400 mx-auto mb-4" />
      <p className="text-gray-600 mb-4">
        Deze persoon heeft nog geen verlanglijst! Goed nieuws: via deze weg kan je gemakkelijk een link versturen en wij doen de rest!
      </p>
      <button
        onClick={() => setShowShareModal(true)}
        className="inline-flex items-center px-4 py-2 bg-warm-olive text-white rounded-md hover:bg-cool-olive transition-colors"
      >
        <Gift className="h-5 w-5 mr-2" />
        Vraag om verlanglijst
      </button>

      {showShareModal && (
        <ShareWishlistModal
          isOpen={showShareModal}
          onClose={() => setShowShareModal(false)}
          recipientName={recipientFirstName && recipientLastName ? `${recipientFirstName} ${recipientLastName}` : 'deze persoon'}
          recipientFirstName={recipientFirstName || 'deze persoon'}
        />
      )}
    </div>
  );
}