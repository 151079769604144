import { 
  doc,
  setDoc,
  getDoc,
  updateDoc,
  deleteDoc,
  collection,
  query,
  where,
  getDocs,
  DocumentReference,
  Query,
  DocumentData,
  serverTimestamp
} from 'firebase/firestore';
import { db } from '../config/firebase';

export const databaseService = {
  async create<T extends DocumentData>(
    collectionName: string,
    id: string,
    data: T
  ) {
    const docRef = doc(db, collectionName, id);
    const timestamp = serverTimestamp();
    await setDoc(docRef, {
      ...data,
      createdAt: timestamp,
      updatedAt: timestamp
    });
    return docRef;
  },

  async read<T>(docRef: DocumentReference<T>) {
    const docSnap = await getDoc(docRef);
    return docSnap.exists() ? docSnap.data() : null;
  },

  async update(
    collectionName: string,
    id: string,
    data: Partial<DocumentData>
  ) {
    const docRef = doc(db, collectionName, id);
    await updateDoc(docRef, {
      ...data,
      updatedAt: serverTimestamp()
    });
  },

  async delete(collectionName: string, id: string) {
    const docRef = doc(db, collectionName, id);
    await deleteDoc(docRef);
  },

  async queryCollection<T>(q: Query<T>) {
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
  }
};