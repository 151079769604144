import React, { useState } from 'react';
import { X, Mail, Copy } from 'lucide-react';
import { toast } from 'react-toastify';

interface ShareWishlistModalProps {
  isOpen: boolean;
  onClose: () => void;
  recipientName: string;
  recipientFirstName: string;
}

export default function ShareWishlistModal({ 
  isOpen, 
  onClose, 
  recipientName,
  recipientFirstName
}: ShareWishlistModalProps) {
  const [senderInfo, setSenderInfo] = useState({
    firstName: '',
    lastName: '',
    email: ''
  });

  if (!isOpen) return null;

  const createWishlistUrl = `${window.location.origin}/create-wishlist`;

  const shareMessage = `Hallo ${recipientFirstName},

${senderInfo.firstName} ${senderInfo.lastName} wil graag weten wat jouw verlanglijstje is om voor jou een cadeautje te kopen.
Via deze link kan je gemakkelijk zelf een lijstje maken en je laten verrassen!

${createWishlistUrl}

Groetjes,

${senderInfo.firstName} & Het Wish2Share-Team`;

  const validateEmail = (email: string) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const handleEmailShare = () => {
    if (!validateEmail(senderInfo.email)) {
      toast.error('Voer een geldig e-mailadres in');
      return;
    }

    const mailtoLink = `mailto:?subject=Maak je verlanglijst aan op Wish2Share!&body=${encodeURIComponent(shareMessage)}`;
    window.location.href = mailtoLink;
    onClose();
  };

  const handleWhatsAppShare = () => {
    const whatsappUrl = `https://wa.me/?text=${encodeURIComponent(shareMessage)}`;
    window.open(whatsappUrl, '_blank');
    onClose();
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(shareMessage);
    toast.success('Bericht gekopieerd naar klembord', {
      onClose: () => {
        toast.info('U kan de link nu versturen', { autoClose: 3000 });
      }
    });
    onClose();
  };

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="flex min-h-full items-center justify-center p-4">
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75" onClick={onClose} />
        
        <div className="relative w-full max-w-md bg-white rounded-lg shadow-xl">
          <div className="p-6">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-semibold text-gray-900">Vraag om verlanglijst</h2>
              <button onClick={onClose} className="text-gray-400 hover:text-gray-500">
                <X className="h-6 w-6" />
              </button>
            </div>

            <div className="space-y-6">
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Jouw voornaam *
                </label>
                <input
                  type="text"
                  value={senderInfo.firstName}
                  onChange={(e) => setSenderInfo({ ...senderInfo, firstName: e.target.value })}
                  className="mt-1 block w-full rounded-md border-2 border-gray-300 shadow-sm focus:border-warm-olive focus:ring-warm-olive"
                  required
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Jouw achternaam *
                </label>
                <input
                  type="text"
                  value={senderInfo.lastName}
                  onChange={(e) => setSenderInfo({ ...senderInfo, lastName: e.target.value })}
                  className="mt-1 block w-full rounded-md border-2 border-gray-300 shadow-sm focus:border-warm-olive focus:ring-warm-olive"
                  required
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Jouw e-mailadres
                </label>
                <p className="text-sm text-gray-500 mb-2">
                  Als u hier uw e-mailadres invult, houden wij u automatisch op de hoogte wanneer {recipientFirstName} een verlanglijstje aangemaakt heeft.
                </p>
                <input
                  type="email"
                  value={senderInfo.email}
                  onChange={(e) => setSenderInfo({ ...senderInfo, email: e.target.value })}
                  className="mt-1 block w-full rounded-md border-2 border-gray-300 shadow-sm focus:border-warm-olive focus:ring-warm-olive"
                  placeholder="voorbeeld@email.com"
                />
              </div>

              {senderInfo.firstName && senderInfo.lastName && (
                <div className="space-y-4">
                  <h3 className="text-lg font-medium text-gray-900">Verstuur via:</h3>
                  
                  <div className="grid grid-cols-3 gap-4">
                    <button
                      onClick={handleEmailShare}
                      className="flex flex-col items-center space-y-2 p-4 rounded-lg hover:bg-gray-50"
                    >
                      <Mail className="h-8 w-8 text-gray-600" />
                      <span className="text-sm text-gray-600">E-mail</span>
                    </button>

                    <button
                      onClick={handleWhatsAppShare}
                      className="flex flex-col items-center space-y-2 p-4 rounded-lg hover:bg-gray-50"
                    >
                      <img 
                        src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg" 
                        alt="WhatsApp"
                        className="h-8 w-8"
                      />
                      <span className="text-sm text-gray-600">WhatsApp</span>
                    </button>

                    <button
                      onClick={handleCopyLink}
                      className="flex flex-col items-center space-y-2 p-4 rounded-lg hover:bg-gray-50"
                    >
                      <Copy className="h-8 w-8 text-gray-600" />
                      <span className="text-sm text-gray-600">Kopieer</span>
                    </button>
                  </div>
                </div>
              )}

              <div className="flex justify-end">
                <button
                  onClick={onClose}
                  className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50"
                >
                  Sluiten
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}