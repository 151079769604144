import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';
import { X } from 'lucide-react';
import { toast } from 'react-toastify';
import { Event } from '../types/event';

interface ParticipantSelectionModalProps {
  event: Event;
  onClose: () => void;
}

export default function ParticipantSelectionModal({ event, onClose }: ParticipantSelectionModalProps) {
  const [selectedParticipantId, setSelectedParticipantId] = useState('');
  const { showLoginModal } = useAuth();
  const navigate = useNavigate();

  // Convert participants object to sorted array
  const participants = Object.entries(event.participants)
    .map(([id, data]) => ({ id, ...data }))
    .filter(p => !p.confirmed) // Only show unconfirmed participants
    .sort((a, b) => 
      `${a.firstName} ${a.lastName}`.localeCompare(`${b.firstName} ${b.lastName}`)
    );

  const handleContinue = () => {
    if (!selectedParticipantId) {
      toast.error('Selecteer eerst je naam uit de lijst');
      return;
    }

    const participant = event.participants[selectedParticipantId];
    if (!participant) return;

    // Store selection in session storage before showing login modal
    sessionStorage.setItem('selectedParticipantId', selectedParticipantId);
    sessionStorage.setItem('selectedEventId', event.id);

    showLoginModal(() => {
      // After successful login, the EventPage component will handle the redirect
      navigate(`/event/${event.id}`);
    });
  };

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="flex min-h-full items-center justify-center p-4">
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75" onClick={onClose} />
        
        <div className="relative w-full max-w-md bg-white rounded-lg shadow-xl">
          <div className="p-6">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-semibold text-gray-900">
                Welkom bij {event.name}
              </h2>
              <button onClick={onClose} className="text-gray-400 hover:text-gray-500">
                <X className="h-6 w-6" />
              </button>
            </div>

            <div className="space-y-4">
              <p className="text-gray-600">
                Selecteer je naam uit de lijst om deel te nemen aan dit evenement.
              </p>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Kies je naam
                </label>
                <select
                  value={selectedParticipantId}
                  onChange={(e) => setSelectedParticipantId(e.target.value)}
                  className="mt-1 block w-full rounded-md border-2 border-gray-300 shadow-sm focus:border-warm-olive focus:ring-warm-olive"
                >
                  <option value="">Selecteer je naam</option>
                  {participants.map((participant) => (
                    <option key={participant.id} value={participant.id}>
                      {participant.firstName} {participant.lastName}
                    </option>
                  ))}
                </select>
              </div>

              <div className="flex justify-end space-x-3 pt-4">
                <button
                  onClick={onClose}
                  className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50"
                >
                  Annuleren
                </button>
                <button
                  onClick={handleContinue}
                  className="px-4 py-2 bg-warm-olive text-white rounded-md hover:bg-cool-olive"
                >
                  Ga verder
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}