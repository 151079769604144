import { StateCreator } from 'zustand';
import { collection, doc, setDoc, updateDoc, deleteDoc, query, where, getDocs, serverTimestamp } from 'firebase/firestore';
import { db } from '../../config/firebase';
import { StoreState } from '../types';
import { Wishlist } from '../../types/wishlist';
import { handleError } from '../../services/errorHandling';

export interface WishlistSlice {
  wishlists: Wishlist[];
  loadWishlists: () => Promise<void>;
  createWishlist: (wishlistData: Partial<Wishlist>) => Promise<string>;
  updateWishlist: (wishlistId: string, data: Partial<Wishlist>) => Promise<void>;
  deleteWishlist: (wishlistId: string) => Promise<void>;
}

export const createWishlistSlice: StateCreator<StoreState, [], [], WishlistSlice> = (set, get) => ({
  wishlists: [],

  loadWishlists: async () => {
    const { currentUser } = get();
    if (!currentUser) return;

    try {
      const wishlistsRef = collection(db, 'wishlists');
      const q = query(wishlistsRef, where('userId', '==', currentUser.id));
      const querySnapshot = await getDocs(q);

      const wishlists = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      })) as Wishlist[];

      set({ wishlists });
    } catch (error) {
      handleError(error);
    }
  },

  createWishlist: async (wishlistData) => {
    const { currentUser } = get();
    if (!currentUser) throw new Error('Je moet ingelogd zijn om een verlanglijst aan te maken');

    try {
      const wishlistId = crypto.randomUUID();
      const wishlistDoc = {
        ...wishlistData,
        userId: currentUser.id,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp()
      };

      await setDoc(doc(db, 'wishlists', wishlistId), wishlistDoc);
      
      const { wishlists } = get();
      set({ wishlists: [{ ...wishlistDoc, id: wishlistId }, ...wishlists] });

      return wishlistId;
    } catch (error) {
      throw handleError(error);
    }
  },

  updateWishlist: async (wishlistId, data) => {
    try {
      await updateDoc(doc(db, 'wishlists', wishlistId), {
        ...data,
        updatedAt: serverTimestamp()
      });
      
      const { wishlists } = get();
      const updatedWishlists = wishlists.map(wishlist =>
        wishlist.id === wishlistId
          ? { ...wishlist, ...data, updatedAt: new Date() }
          : wishlist
      );
      
      set({ wishlists: updatedWishlists });
    } catch (error) {
      throw handleError(error);
    }
  },

  deleteWishlist: async (wishlistId) => {
    try {
      await deleteDoc(doc(db, 'wishlists', wishlistId));
      
      const { wishlists } = get();
      set({ wishlists: wishlists.filter(w => w.id !== wishlistId) });
    } catch (error) {
      throw handleError(error);
    }
  }
});