import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Search } from 'lucide-react';
import Hero from '../components/Hero';
import Features from '../components/Features';
import HowItWorks from '../components/HowItWorks';
import { useStore } from '../store/useStore';
import { useAuth } from '../components/AuthContext';

export default function Home() {
  const navigate = useNavigate();
  const { currentUser } = useStore();
  const { showLoginModal } = useAuth();

  const handleCreateWishlist = () => {
    navigate('/create-wishlist');
  };

  const handleSearch = () => {
    navigate('/search');
  };

  return (
    <div>
      <Hero onCreateWishlist={handleCreateWishlist} />
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 pb-4">
        <div className="flex justify-center">
          <button
            onClick={handleSearch}
            className="w-[95%] sm:w-full bg-warm-olive text-white px-6 py-4 rounded-lg hover:bg-cool-olive transition-colors flex items-center justify-center space-x-3 text-lg -mt-4"
          >
            <Search className="h-6 w-6" />
            <span>Zoek verlanglijstjes van vrienden</span>
          </button>
        </div>
      </div>
      <div className="mt-4">
        <Features />
        <HowItWorks />
      </div>
    </div>
  );
}