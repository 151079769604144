import React, { useState } from 'react';
import { useStore } from '../store/useStore';
import { toast } from 'react-toastify';

const inputClasses = "mt-1 block w-full rounded-md border-2 border-gray-300 shadow-sm focus:border-warm-olive focus:ring-warm-olive focus:ring-opacity-50 bg-white px-3 py-2";

export default function PasswordChangeSection() {
  const { updatePassword } = useStore();
  const [passwordData, setPasswordData] = useState({
    currentPassword: '',
    newPassword: '',
    confirmNewPassword: ''
  });
  const [isChangingPassword, setIsChangingPassword] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (passwordData.newPassword !== passwordData.confirmNewPassword) {
      toast.error('Nieuwe wachtwoorden komen niet overeen');
      return;
    }

    if (passwordData.newPassword.length < 6) {
      toast.error('Nieuw wachtwoord moet minimaal 6 tekens bevatten');
      return;
    }

    try {
      setIsChangingPassword(true);
      await updatePassword(passwordData.currentPassword, passwordData.newPassword);
      toast.success('Wachtwoord succesvol gewijzigd');
      setPasswordData({
        currentPassword: '',
        newPassword: '',
        confirmNewPassword: ''
      });
    } catch (error: any) {
      if (error.code === 'auth/wrong-password') {
        toast.error('Huidig wachtwoord is onjuist');
      } else {
        toast.error('Fout bij het wijzigen van wachtwoord');
      }
    } finally {
      setIsChangingPassword(false);
    }
  };

  return (
    <div className="bg-white shadow rounded-lg p-6">
      <h2 className="text-xl font-semibold text-gray-900 mb-4">Wachtwoord Wijzigen</h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Huidig Wachtwoord
          </label>
          <input
            type="password"
            value={passwordData.currentPassword}
            onChange={(e) => setPasswordData({ ...passwordData, currentPassword: e.target.value })}
            className={inputClasses}
            required
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Nieuw Wachtwoord
          </label>
          <input
            type="password"
            value={passwordData.newPassword}
            onChange={(e) => setPasswordData({ ...passwordData, newPassword: e.target.value })}
            className={inputClasses}
            required
            minLength={6}
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Bevestig Nieuw Wachtwoord
          </label>
          <input
            type="password"
            value={passwordData.confirmNewPassword}
            onChange={(e) => setPasswordData({ ...passwordData, confirmNewPassword: e.target.value })}
            className={inputClasses}
            required
          />
        </div>
        <div className="flex justify-end">
          <button
            type="submit"
            disabled={isChangingPassword}
            className="bg-warm-olive text-white px-4 py-2 rounded-md hover:bg-cool-olive disabled:opacity-50"
          >
            {isChangingPassword ? 'Bezig met wijzigen...' : 'Wijzig Wachtwoord'}
          </button>
        </div>
      </form>
    </div>
  );
}