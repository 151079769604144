import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useStore } from '../store/useStore';
import { toast } from 'react-toastify';
import { motion } from 'framer-motion';

export default function WishlistRequestPage() {
  const { eventId, participantId } = useParams<{ eventId: string; participantId: string }>();
  const navigate = useNavigate();
  const { events, currentUser } = useStore();
  const [recipientEmail, setRecipientEmail] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isSending, setIsSending] = useState(false);
  const [invitationText, setInvitationText] = useState('');

  const event = events.find(e => e.id === eventId);
  const participant = event?.participants[participantId];

  useEffect(() => {
    if (!event || !participant || !currentUser) {
      navigate('/dashboard');
    } else {
      // Set default invitation text
      const defaultText = `Hallo ${participant.firstName},

Ik zou graag weten wat je leuk vindt om een cadeautje voor jou te kopen!
Zou je een verlanglijstje willen maken? Dan kan ik je verrassen!

Je kunt hier je verlanglijstje maken: ${window.location.origin}/create-wishlist

Groetjes, ... Oh! Ik had bijna mijn naam geschreven :D`;
      
      setInvitationText(defaultText);
      setIsLoading(false);
    }
  }, [event, participant, currentUser, navigate]);

  if (isLoading) {
    return null;
  }

  const handleSendInvite = async () => {
    const email = participant.email || recipientEmail;
    if (!email) {
      toast.error('Voer een geldig e-mailadres in');
      return;
    }

    setIsSending(true);
    try {
      // Here you would integrate with your email service
      // For now, we'll simulate sending with a timeout
      await new Promise(resolve => setTimeout(resolve, 1000));
      
      toast.success('Verstuurd! De lijst komt eraan!');
      navigate(`/event/${eventId}`);
    } catch (error) {
      toast.error('Oei! Er is geen uitnodiging gestuurd! Vraag de organisator om een reminder te sturen ;D');
      setIsSending(false);
    }
  };

  return (
    <div className="max-w-2xl mx-auto px-4 py-8">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="bg-white rounded-lg shadow-sm p-6"
      >
        <h1 className="text-2xl font-bold text-gray-900 mb-6">
          Vraag om verlanglijst
        </h1>

        <div className="mb-8">
          {participant.email ? (
            <p className="text-gray-600">
              {participant.firstName} heeft nog geen verlanglijstje maar goed nieuws! 
              Klik op 'Nodig uit' en wij doen de rest zonder je identiteit te onthullen!
            </p>
          ) : (
            <p className="text-gray-600">
              {participant.firstName} heeft zich nog niet geregistreerd maar goed nieuws! 
              Vul hier het e-mailadres in en wij doen de rest zonder je identiteit te onthullen!
            </p>
          )}
        </div>

        {!participant.email && (
          <div className="mb-6">
            <label className="block text-sm font-medium text-gray-700 mb-2">
              E-mailadres
            </label>
            <input
              type="email"
              value={recipientEmail}
              onChange={(e) => setRecipientEmail(e.target.value)}
              className="w-full rounded-md border-2 border-gray-300 shadow-sm focus:border-warm-olive focus:ring-warm-olive p-2"
              placeholder="naam@voorbeeld.nl"
              required
            />
          </div>
        )}

        <div className="mb-6">
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Uitnodigingstekst
          </label>
          <textarea
            value={invitationText}
            onChange={(e) => setInvitationText(e.target.value)}
            className="w-full h-48 rounded-md border-2 border-gray-300 shadow-sm focus:border-warm-olive focus:ring-warm-olive p-2"
            placeholder="Schrijf hier je uitnodiging..."
          />
        </div>

        <div className="flex justify-between mt-8">
          <button
            onClick={() => navigate(`/event/${eventId}`)}
            className="px-4 py-2 text-gray-600 hover:text-gray-800"
          >
            Terug naar evenement
          </button>
          <button
            onClick={handleSendInvite}
            disabled={isSending || (!participant.email && !recipientEmail)}
            className="px-6 py-2 bg-warm-olive text-white rounded-md hover:bg-cool-olive disabled:opacity-50 disabled:cursor-not-allowed"
          >
            {isSending ? 'Bezig met versturen...' : 'Nodig uit'}
          </button>
        </div>
      </motion.div>
    </div>
  );
}