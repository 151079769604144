import React, { createContext, useContext, useState, useEffect } from 'react';
import { useStore } from '../store/useStore';
import { authService } from '../services/auth';
import LoginModal from './LoginModal';
import RegisterModal from './RegisterModal';

interface AuthContextType {
  showLoginModal: (onSuccess?: () => void) => void;
  showRegisterModal: () => void;
  isAuthenticated: boolean;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [isRegisterModalOpen, setIsRegisterModalOpen] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loginSuccessCallback, setLoginSuccessCallback] = useState<(() => void) | undefined>();
  const { loadEvents, loadWishlists } = useStore();

  useEffect(() => {
    const unsubscribe = authService.onAuthStateChanged(async (user) => {
      setIsAuthenticated(!!user);
      
      if (user) {
        try {
          // Load user data and lists
          await Promise.all([
            loadEvents(),
            loadWishlists()
          ]);
        } catch (error) {
          console.error('Error loading user data:', error);
        }
      }
    });

    return () => unsubscribe();
  }, [loadEvents, loadWishlists]);

  const handleSwitchToRegister = () => {
    setIsLoginModalOpen(false);
    setIsRegisterModalOpen(true);
  };

  const handleSwitchToLogin = () => {
    setIsRegisterModalOpen(false);
    setIsLoginModalOpen(true);
  };

  const handleLoginSuccess = () => {
    if (loginSuccessCallback) {
      loginSuccessCallback();
      setLoginSuccessCallback(undefined);
    }
    setIsLoginModalOpen(false);
  };

  const showLoginModal = (onSuccess?: () => void) => {
    if (!isAuthenticated) {
      setIsLoginModalOpen(true);
      if (onSuccess) {
        setLoginSuccessCallback(() => onSuccess);
      }
    }
  };
  
  const showRegisterModal = () => {
    if (!isAuthenticated) {
      setIsRegisterModalOpen(true);
    }
  };

  return (
    <AuthContext.Provider value={{ showLoginModal, showRegisterModal, isAuthenticated }}>
      {children}
      <LoginModal
        isOpen={isLoginModalOpen}
        onClose={() => setIsLoginModalOpen(false)}
        onSwitchToRegister={handleSwitchToRegister}
        onLoginSuccess={handleLoginSuccess}
      />
      <RegisterModal
        isOpen={isRegisterModalOpen}
        onClose={() => setIsRegisterModalOpen(false)}
        onSwitchToLogin={handleSwitchToLogin}
      />
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}