import React from 'react';
import { useNavigate } from 'react-router-dom';

interface ErrorFallbackProps {
  error: Error;
}

export default function ErrorFallback({ error }: ErrorFallbackProps) {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen flex items-center justify-center px-4">
      <div className="max-w-md w-full bg-white rounded-lg shadow-lg p-6">
        <h2 className="text-2xl font-bold text-red-600 mb-4">
          Er is iets misgegaan
        </h2>
        <p className="text-gray-600 mb-6">
          {error.message || 'Er is een onverwachte fout opgetreden.'}
        </p>
        <div className="flex justify-end space-x-3">
          <button
            onClick={() => window.location.reload()}
            className="px-4 py-2 bg-gray-200 text-gray-700 rounded-md hover:bg-gray-300"
          >
            Ververs pagina
          </button>
          <button
            onClick={() => navigate('/dashboard')}
            className="px-4 py-2 bg-warm-olive text-white rounded-md hover:bg-cool-olive"
          >
            Terug naar Dashboard
          </button>
        </div>
      </div>
    </div>
  );
}