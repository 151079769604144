import React from 'react';
import FilterButton from '../FilterButton';

interface CategoryFiltersProps {
  selectedFilters: string[];
  onFilterToggle: (filter: string) => void;
}

export const categories = [
  { label: 'Boeken', value: 'Books' },
  { label: 'Elektronica', value: 'Electronics' },
  { label: 'Kleding', value: 'Apparel' },
  { label: 'Sport', value: 'SportingGoods' }
];

export default function CategoryFilters({ selectedFilters, onFilterToggle }: CategoryFiltersProps) {
  return (
    <div className="flex flex-wrap gap-2">
      {categories.map(category => (
        <FilterButton
          key={category.value}
          label={category.label}
          value={category.value}
          isSelected={selectedFilters.includes(category.value)}
          onClick={() => onFilterToggle(category.value)}
        />
      ))}
    </div>
  );
}