import React from 'react';
import RequiredFieldMarker from '../RequiredFieldMarker';
import DateInput from '../DateInput';

interface PersonalInfo {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  birthdate: string;
}

interface PersonalInfoSectionProps {
  data: PersonalInfo;
  onChange: (field: keyof PersonalInfo, value: string) => void;
}

export default function PersonalInfoSection({ data, onChange }: PersonalInfoSectionProps) {
  return (
    <div className="bg-white shadow rounded-lg p-4">
      <h2 className="text-lg font-semibold text-gray-900 mb-3">Persoonlijke Informatie</h2>
      <div className="grid grid-cols-1 gap-3 sm:grid-cols-2">
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Voornaam<RequiredFieldMarker />
          </label>
          <input
            type="text"
            required
            value={data.firstName}
            onChange={(e) => onChange('firstName', e.target.value)}
            className="mt-1 block w-full rounded-md border-2 border-gray-300 shadow-sm focus:border-warm-olive focus:ring-warm-olive"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Achternaam<RequiredFieldMarker />
          </label>
          <input
            type="text"
            required
            value={data.lastName}
            onChange={(e) => onChange('lastName', e.target.value)}
            className="mt-1 block w-full rounded-md border-2 border-gray-300 shadow-sm focus:border-warm-olive focus:ring-warm-olive"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">
            E-mailadres<RequiredFieldMarker />
          </label>
          <input
            type="email"
            required
            value={data.email}
            onChange={(e) => onChange('email', e.target.value)}
            className="mt-1 block w-full rounded-md border-2 border-gray-300 shadow-sm focus:border-warm-olive focus:ring-warm-olive"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">
            GSM-nummer
          </label>
          <input
            type="tel"
            value={data.phone}
            onChange={(e) => onChange('phone', e.target.value)}
            className="mt-1 block w-full rounded-md border-2 border-gray-300 shadow-sm focus:border-warm-olive focus:ring-warm-olive"
          />
        </div>
        <div className="sm:col-span-2">
          <DateInput
            label="Geboortedatum"
            value={data.birthdate}
            onChange={(value) => onChange('birthdate', value)}
            required
            maxDate={new Date().toISOString().split('T')[0]}
          />
        </div>
      </div>
    </div>
  );
}