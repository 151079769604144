import React from 'react';
import { motion } from 'framer-motion';
import { X, AlertCircle, CheckCircle, Info, AlertTriangle } from 'lucide-react';
import { NotificationType } from '../../utils/notifications';

interface NotificationItemProps {
  id: string;
  type: NotificationType;
  message: string;
  onClose: (id: string) => void;
}

const notificationStyles = {
  success: {
    bg: 'bg-green-500',
    icon: CheckCircle
  },
  error: {
    bg: 'bg-red-500',
    icon: AlertCircle
  },
  info: {
    bg: 'bg-blue-500',
    icon: Info
  },
  warning: {
    bg: 'bg-yellow-500',
    icon: AlertTriangle
  }
};

export default function NotificationItem({ id, type, message, onClose }: NotificationItemProps) {
  const { bg, icon: Icon } = notificationStyles[type];

  return (
    <motion.div
      initial={{ opacity: 0, y: -20, scale: 0.95 }}
      animate={{ opacity: 1, y: 0, scale: 1 }}
      exit={{ opacity: 0, y: -20, scale: 0.95 }}
      className={`${bg} text-white rounded-lg shadow-lg p-4 flex items-center min-w-[320px] max-w-md`}
    >
      <Icon className="h-5 w-5 flex-shrink-0" />
      <p className="ml-3 mr-8 flex-grow">{message}</p>
      <button
        onClick={() => onClose(id)}
        className="flex-shrink-0 ml-auto hover:opacity-80 transition-opacity"
      >
        <X className="h-5 w-5" />
      </button>
    </motion.div>
  );
}