import React from 'react';
import { Filter } from 'lucide-react';
import PriceFilters from './filters/PriceFilters';
import CategoryFilters from './filters/CategoryFilters';

interface FilterBarProps {
  selectedFilters: string[];
  onFilterToggle: (filter: string) => void;
}

export default function FilterBar({ selectedFilters, onFilterToggle }: FilterBarProps) {
  return (
    <div className="space-y-4">
      <div className="flex items-center gap-2 text-gray-600">
        <Filter className="h-5 w-5" />
        <span className="text-sm font-medium">Filters</span>
      </div>
      
      <div className="space-y-4">
        <div>
          <h3 className="text-sm font-medium text-gray-700 mb-2">Prijsklasse</h3>
          <PriceFilters 
            selectedFilters={selectedFilters} 
            onFilterToggle={onFilterToggle} 
          />
        </div>

        <div>
          <h3 className="text-sm font-medium text-gray-700 mb-2">Categorieën</h3>
          <CategoryFilters 
            selectedFilters={selectedFilters} 
            onFilterToggle={onFilterToggle} 
          />
        </div>
      </div>
    </div>
  );
}