import React from 'react';
import RequiredFieldMarker from '../RequiredFieldMarker';
import { EventForm } from '../../types/eventForm';

interface EventBasicFormProps {
  eventData: EventForm;
  onUpdate: (data: Partial<EventForm>) => void;
}

export default function EventBasicForm({ eventData, onUpdate }: EventBasicFormProps) {
  return (
    <div className="space-y-6">
      <div>
        <label className="block text-sm font-medium text-gray-700">
          Naam van het evenement<RequiredFieldMarker />
        </label>
        <input
          type="text"
          value={eventData.name}
          onChange={(e) => onUpdate({ name: e.target.value })}
          className="mt-1 block w-full rounded-md border-2 border-gray-300 shadow-sm focus:border-warm-olive focus:ring-warm-olive"
          placeholder="Bijvoorbeeld: Kerstmis 2024"
          required
        />
      </div>

      <div className="grid grid-cols-2 gap-4">
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Datum<RequiredFieldMarker />
          </label>
          <input
            type="date"
            value={eventData.date}
            onChange={(e) => onUpdate({ date: e.target.value })}
            className="mt-1 block w-full rounded-md border-2 border-gray-300 shadow-sm focus:border-warm-olive focus:ring-warm-olive"
            required
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">Tijd</label>
          <input
            type="time"
            value={eventData.time}
            onChange={(e) => onUpdate({ time: e.target.value })}
            className="mt-1 block w-full rounded-md border-2 border-gray-300 shadow-sm focus:border-warm-olive focus:ring-warm-olive"
          />
        </div>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">Budget per persoon (€)</label>
        <input
          type="number"
          value={eventData.budget || ''}
          onChange={(e) => onUpdate({ budget: e.target.value ? parseFloat(e.target.value) : undefined })}
          className="mt-1 block w-full rounded-md border-2 border-gray-300 shadow-sm focus:border-warm-olive focus:ring-warm-olive"
          placeholder="25"
          min="0"
          step="0.01"
        />
      </div>

      <div className="space-y-4">
        <div>
          <label className="flex items-center space-x-3">
            <input
              type="checkbox"
              checked={eventData.isLootjesEvent}
              onChange={(e) => onUpdate({ isLootjesEvent: e.target.checked })}
              className="h-4 w-4 text-warm-olive focus:ring-warm-olive border-gray-300 rounded"
            />
            <span className="text-sm font-medium text-gray-700">Dit is een lootjes evenement</span>
          </label>
        </div>

        {eventData.isLootjesEvent && (
          <div>
            <label className="block text-sm font-medium text-gray-700">Deadline voor registratie</label>
            <input
              type="date"
              value={eventData.registrationDeadline}
              onChange={(e) => onUpdate({ registrationDeadline: e.target.value })}
              className="mt-1 block w-full rounded-md border-2 border-gray-300 shadow-sm focus:border-warm-olive focus:ring-warm-olive"
              max={eventData.date}
            />
          </div>
        )}
      </div>
    </div>
  );
}