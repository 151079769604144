import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Link, Edit2, Trash2 } from 'lucide-react';
import { useStore } from '../../../store/useStore';
import { useNotification } from '../../../hooks/useNotification';

interface WishlistActionsProps {
  wishlistId: string;
  onCopyLink: (e: React.MouseEvent) => void;
}

export default function WishlistActions({ wishlistId, onCopyLink }: WishlistActionsProps) {
  const navigate = useNavigate();
  const { deleteWishlist } = useStore();
  const { showSuccess, showError } = useNotification();

  const handleEdit = (e: React.MouseEvent) => {
    e.stopPropagation();
    navigate(`/wishlist/${wishlistId}/edit`);
  };

  const handleDelete = async (e: React.MouseEvent) => {
    e.stopPropagation();
    if (!window.confirm('Weet je zeker dat je deze verlanglijst wilt verwijderen?')) return;

    try {
      await deleteWishlist(wishlistId);
      showSuccess('Verlanglijst verwijderd');
    } catch (error) {
      showError('Er ging iets mis bij het verwijderen van de verlanglijst');
    }
  };

  return (
    <div className="flex space-x-2">
      <button
        onClick={onCopyLink}
        className="p-1 text-gray-400 hover:text-gray-600"
      >
        <Link className="h-5 w-5" />
      </button>
      <button
        onClick={handleEdit}
        className="p-1 text-gray-400 hover:text-gray-600"
      >
        <Edit2 className="h-5 w-5" />
      </button>
      <button
        onClick={handleDelete}
        className="p-1 text-red-400 hover:text-red-600"
      >
        <Trash2 className="h-5 w-5" />
      </button>
    </div>
  );
}