import React from 'react';

export default function PrivacyPage() {
  return (
    <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <div className="bg-white rounded-lg shadow-sm p-8">
        <h1 className="text-3xl font-bold text-gray-900 mb-6">Privacy en Voorwaarden</h1>
        <div className="space-y-6">
          <section>
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">Versie: 2024</h2>
            <p className="text-gray-600">
              Leliflow VVRZL is opgericht in 2024 en is ingeschreven in België onder BTW-nummer BE 1012.173.224.
Wish2Share vindt het belangrijk om u duidelijk te informeren over de verwerking van persoonsgegevens. 
              Tijdens het lootjes trekken verwerkt Wish2Share een aantal persoonsgegevens.
Doeleinden verwerking
Wish2Share verwerkt jouw persoonsgegevens met als doel:
* Om namen te trekken en een verlanglijstje te maken.
* Voor het afstemmen van onze website op uw wensen en het bijhouden van uw persoonlijke voorkeuren.
* Voor het maken van analyses ter verbetering van onze dienstverlening.
* Om je te informeren over onze website.
Leliflow VVRZL verwerkt uw persoonsgegevens op basis van de volgende wettelijke grondslag:
De verwerking van uw persoonsgegevens is noodzakelijk ter behartiging van de/het volgende gerechtvaardigde belang(en) van Leliflow VVRZL: Het kunnen uitvoeren van de dienst waarvoor bezoekers Leliflow VVRZL gebruiken, zoals het trekken van namen, het kenbaar maken van de wensen, de uitwisseling hiervan en het informeren van de gebruikers.
Jouw privacy
Wish2Share waarborgt jouw privacy op de volgende manieren:
* Wij verwerken uitsluitend persoonsgegevens die nodig zijn om namen te trekken en een verlanglijstje te maken.
* Wij verstrekken geen persoonsgegevens aan derden zoals adverteerders. Jouw e-mailadres wordt dus alleen gebruikt voor het versturen van e-mail door Wish2Share.
* Wij plaatsen alleen cookies die nodig zijn voor de werking van de website en statistische analyse. Deze cookies bevatten anonieme informatie en blijven maximaal één jaar in jouw browser aanwezig.
* Wij plaatsen geen cookies namens derden (third-party cookies).
* Wij versleutelen alle pagina's met SSL/HTTPS: het slotje in jouw browser.
* Wij slaan gegevens op binnen de grenzen van de Europese Unie.
Analytics
Wish2Share verzamelt via Google Analytics anonieme gebruiksgegevens voor analysedoeleinden. Statistische gebruiksanalyses stellen ons in staat de website continu te verbeteren. Om jouw privacy te waarborgen zijn de volgende maatregelen getroffen:
* Er wordt een anoniem cookie gebruikt.
* Er is een bewerkersovereenkomst met Google gesloten.
* Het laatste octet van jouw IP-adres wordt gemaskeerd.
* ‘Gegevens delen’ met Google is uitgezet.
* Er wordt geen gebruik gemaakt van andere Google-diensten in combinatie met Google Analytics.
* Je kunt je afmelden voor Google Analytics.
Cookies
Wij gebruiken cookies waarmee wij je relevante informatie kunnen tonen op andere websites en sociale media. Ook gebruiken wij vergelijkbare technieken zoals meet- en conversiepixels om de doeltreffendheid van onze online advertenties te meten en te verbeteren. Om jouw privacy te waarborgen zijn de volgende maatregelen getroffen:
* Je kunt de website gebruiken zonder marketing-cookies door bij het eerste bezoek op ‘Niet akkoord’ te klikken.
* Er is een bewerkersovereenkomst gesloten met advertentienetwerken waarmee wij samenwerken.
* Wij hebben advertentienetwerken geen toestemming gegeven om de verkregen informatie te gebruiken voor diensten gericht aan derden.
Verstrekking persoonsgegevens
Wil je gebruik maken van Wish2Share, dan ben je verplicht om je naam, voornaam, e-mailadres en geboortedatum in te vullen. De volgende gegevens zijn niet verplicht: geslacht, leeftijd, hobby’s & interesses, postadres en cadeauwensen. Indien je deze persoonsgegevens niet aan Leliflow VVRZL verstrekt, dan heeft degene die je naam toegewezen heeft gekregen een minder goed beeld van jouw wensen.
Beveiligen en bewaren
Wish2Share beveiligt en bewaart jouw gegevens op de volgende manieren:
* Wij gaan zorgvuldig om met jouw persoonsgegevens. Dat betekent onder meer dat wij adequate technische en organisatorische maatregelen nemen om jouw persoonsgegevens te beveiligen en te beschermen tegen verlies of oneigenlijke verwerking.
* Jouw persoonsgegevens worden opgeslagen in een beveiligde omgeving en zijn slechts toegankelijk voor daartoe geautoriseerde medewerkers van Leliflow VVRZL voor zover dit noodzakelijk is voor de verwerking van jouw persoonsgegevens, zoals beschreven staat in dit privacystatement.
* Wij bewaren jouw persoonsgegevens niet langer dan noodzakelijk voor de doeleinden waarvoor zij worden verwerkt. Wanneer het niet langer noodzakelijk is om jouw persoonsgegevens te verwerken, zullen wij jouw persoonsgegevens anonimiseren.
Vrijwaring
Deze website is zeer zorgvuldig ontwikkeld. Aan het gebruik van Leliflow VVRZL kunnen echter geen rechten worden ontleend. Leliflow VVRZL aanvaardt geen enkele aansprakelijkheid voor het niet of onjuist functioneren van de website of het niet of niet tijdig aankomen van berichten.
Geautomatiseerde besluitvorming en/of profilering
Voor het afstemmen van de cadeauzoeker op jouw persoonlijke voorkeuren en persoonskenmerken maakt Wish2Share gebruik van geautomatiseerde besluitvorming (waaronder profilering) op basis van jouw persoonsgegevens. Op die manier kunnen wij relevante suggesties doen voor degene die een cadeau voor jou wilt kopen en de kans vergroten dat je een cadeau krijgt dat past bij jouw profiel.
Cadeau-ideeën en externe links
Wish2Share is kosteloos te gebruiken door de bijdrage van onze partners. Wish2Share toont relevante cadeau-ideeën voor jouw feestelijkheden. Door te klikken op een link bezoek je de webwinkel van een partner. Het gebruik van deze externe websites, waaronder het bestellen van cadeaus, valt buiten de verantwoordelijkheid van Wish2Share.
Gegevens verwijderen
Degene die een groep heeft opgezet kan personen (namen en e-mailadressen) uit de groep verwijderen of de hele groep verwijderen. Zie ook onze help-pagina.
Jouw rechten
Je hebt het recht om bij ons een verzoek in te dienen tot inzage, correctie en/of verwijdering van persoonsgegevens die wij van jou verwerken. Hiernaast heb je het recht om ons te verzoeken de verwerking van jouw persoonsgegevens te beperken en om bezwaar te maken tegen de verwerking van jouw persoonsgegevens.
Als betrokkene heb je ook het recht Wish2Share te verzoeken de persoonsgegevens die je zelf aan Wish2Share verstrekt hebt in een gestructureerde, gangbare en machineleesbare vorm terug te krijgen (dataportabiliteit).
Vragen, verzoeken en klachten
Voor vragen over dit privacystatement en de verwerking die Wish2Share doet kan je contact opnemen via onderstaande gegevens.
Een verzoek tot dataportabiliteit, inzage, correctie en/of verwijdering, dan wel beperking en bezwaar kan je schriftelijk via e-mail indienen. Bij deze verzoeken kunnen we jou om identificatie vragen. Als betrokkene heb je tevens het recht om een klacht in te dienen bij de toezichthoudende autoriteit.
Leliflow VVRZL leliflow@icloud.com
              © 2024 LeLiFlow VVRZL. Alle rechten voorbehouden.

            </p>
          </section>
        </div>
      </div>
    </div>
  );
}