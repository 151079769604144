import { FirebaseError } from 'firebase/app';
import { useNotificationStore } from '../store/notificationStore';

export const handleError = (error: unknown): Error => {
  let message = 'Er is een onverwachte fout opgetreden';

  if (error instanceof FirebaseError) {
    message = getFirebaseErrorMessage(error.code);
  } else if (error instanceof Error) {
    message = error.message;
  }

  // Show error notification
  useNotificationStore.getState().addNotification('error', message);

  return new Error(message);
};

const getFirebaseErrorMessage = (code: string): string => {
  const errorMessages: Record<string, string> = {
    'auth/invalid-credential': 'Onjuiste e-mail of wachtwoord.',
    'auth/user-not-found': 'Geen account gevonden met dit e-mailadres.',
    'auth/wrong-password': 'Onjuist wachtwoord.',
    'auth/email-already-in-use': 'Dit e-mailadres is al in gebruik.',
    'auth/weak-password': 'Wachtwoord moet minimaal 6 tekens bevatten.',
    'auth/network-request-failed': 'Netwerkfout. Controleer je internetverbinding.',
    'auth/too-many-requests': 'Te veel mislukte pogingen. Probeer het later opnieuw.',
    'functions/internal': 'Er ging iets mis bij het ophalen van de producten.',
    'permission-denied': 'Je hebt geen toegang tot deze functie.'
  };

  return errorMessages[code] || 'Er is een onverwachte fout opgetreden';
};