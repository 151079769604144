import { getFunctions, httpsCallable } from 'firebase/functions';
import { AmazonProduct, SearchParams } from '../types/amazon';

const functions = getFunctions();
const searchProductsFunction = httpsCallable<SearchParams, AmazonProduct[]>(functions, 'searchProducts');

class AmazonService {
  private retryCount = 0;
  private maxRetries = 3;
  private retryDelay = 1000; // 1 second

  async searchProducts(params: SearchParams): Promise<AmazonProduct[]> {
    if (!params.searchTerm) {
      throw new Error('Zoekterm is verplicht');
    }

    try {
      const result = await this.executeWithRetry(() => searchProductsFunction(params));
      
      if (!result.data || !Array.isArray(result.data)) {
        throw new Error('Ongeldig antwoord van Amazon API');
      }

      return result.data.filter(product => 
        product.ASIN && 
        product.title && 
        product.price && 
        product.image
      );
    } catch (error: any) {
      console.error('Amazon API error:', error);
      
      if (error.code === 'functions/internal') {
        throw new Error('Amazon API is tijdelijk niet beschikbaar. Probeer het later opnieuw.');
      }
      
      throw new Error('Er ging iets mis bij het zoeken van producten. Probeer het opnieuw.');
    }
  }

  private async executeWithRetry<T>(fn: () => Promise<T>): Promise<T> {
    try {
      return await fn();
    } catch (error: any) {
      if (this.retryCount < this.maxRetries && error.code === 'functions/internal') {
        this.retryCount++;
        await new Promise(resolve => setTimeout(resolve, this.retryDelay * this.retryCount));
        return this.executeWithRetry(fn);
      }
      throw error;
    }
  }
}

export const amazonService = new AmazonService();