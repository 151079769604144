import React, { useRef, useEffect } from 'react';
import MessageList from './chat/MessageList';
import ChatInput from './chat/ChatInput';
import { Message } from '../types/event';
import { useScrollToBottom } from '../hooks/useScrollToBottom';

interface GroupChatProps {
  eventId: string;
  messages: Message[];
  onSendMessage: (text: string, isAnonymous: boolean, gifUrl?: string) => Promise<void>;
  onEditMessage?: (messageId: string, newText: string) => Promise<void>;
  onDeleteMessage?: (messageId: string) => Promise<void>;
  currentUserId: string;
  currentUserName: string;
}

export default function GroupChat({
  eventId,
  messages,
  onSendMessage,
  onEditMessage,
  onDeleteMessage,
  currentUserId,
  currentUserName
}: GroupChatProps) {
  const chatContainerRef = useScrollToBottom([messages]);

  return (
    <div className="flex flex-col h-[calc(100vh-12rem)] bg-warm-beige rounded-lg shadow-sm">
      <div className="bg-warm-olive px-4 py-3 flex items-center space-x-3 rounded-t-lg">
        <h2 className="text-white font-semibold">Groepsberichten</h2>
      </div>

      <div 
        ref={chatContainerRef}
        className="flex-1 overflow-y-auto scroll-smooth scrollbar-thin scrollbar-thumb-warm-olive scrollbar-track-transparent"
      >
        <MessageList
          messages={messages}
          eventId={eventId}
          currentUserId={currentUserId}
          onEdit={onEditMessage}
          onDelete={onDeleteMessage}
        />
      </div>

      <ChatInput onSendMessage={onSendMessage} />
    </div>
  );
}