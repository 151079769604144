import { useCallback } from 'react';
import { toast } from 'react-toastify';
import { useStore } from '../store/useStore';
import { Event } from '../types/event';
import { UserProfile } from '../store/types';

export const useEventMessages = (event: Event | undefined, currentUser: UserProfile | null) => {
  const { updateEvent } = useStore();

  const handleSendMessage = useCallback(async (text: string, isAnonymous: boolean, gifUrl?: string) => {
    if (!event || !currentUser) return;

    try {
      const messages = Array.isArray(event.messages) ? event.messages : [];
      const messageData = {
        id: crypto.randomUUID(),
        text,
        userId: currentUser.id,
        userName: isAnonymous 
          ? `Anonieme ${event.name}-fan` 
          : `${currentUser.firstName} ${currentUser.lastName}`,
        timestamp: new Date().toISOString(),
        isAnonymous,
        gifUrl
      };

      await updateEvent(event.id, {
        messages: [...messages, messageData]
      });
    } catch (error) {
      console.error('Send message error:', error);
      toast.error('Bericht versturen mislukt');
    }
  }, [event, currentUser, updateEvent]);

  const handleEditMessage = useCallback(async (messageId: string, newText: string) => {
    if (!event) return;

    try {
      const messages = Array.isArray(event.messages) ? event.messages : [];
      const updatedMessages = messages.map(msg => 
        msg.id === messageId ? { ...msg, text: newText } : msg
      );

      await updateEvent(event.id, {
        messages: updatedMessages
      });
      toast.success('Bericht bijgewerkt');
    } catch (error) {
      console.error('Edit message error:', error);
      toast.error('Bericht bewerken mislukt');
    }
  }, [event, updateEvent]);

  const handleDeleteMessage = useCallback(async (messageId: string) => {
    if (!event) return;

    try {
      const messages = Array.isArray(event.messages) ? event.messages : [];
      const updatedMessages = messages.filter(msg => msg.id !== messageId);

      await updateEvent(event.id, {
        messages: updatedMessages
      });
      toast.success('Bericht verwijderd');
    } catch (error) {
      console.error('Delete message error:', error);
      toast.error('Bericht verwijderen mislukt');
    }
  }, [event, updateEvent]);

  return {
    handleSendMessage,
    handleEditMessage,
    handleDeleteMessage
  };
};