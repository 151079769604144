import { StateCreator } from 'zustand';
import { 
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  updateProfile,
  setPersistence,
  browserLocalPersistence,
  browserSessionPersistence,
  AuthError
} from 'firebase/auth';
import { doc, setDoc, getDoc, updateDoc, Timestamp } from 'firebase/firestore';
import { auth, db } from '../../config/firebase';
import { StoreState, UserProfile } from '../types';
import { handleError } from '../../services/errorHandling';
import { ROUTES } from '../../routes';

export interface AuthSlice {
  currentUser: UserProfile | null;
  loading: boolean;
  error: string | null;
  register: (email: string, password: string, firstName: string, lastName: string, birthdate: string) => Promise<void>;
  login: (email: string, password: string, rememberMe: boolean) => Promise<void>;
  logout: () => Promise<void>;
  updateUserProfile: (data: Partial<UserProfile>) => Promise<void>;
}

export const createAuthSlice: StateCreator<StoreState, [], [], AuthSlice> = (set, get) => ({
  currentUser: null,
  loading: false,
  error: null,

  register: async (email, password, firstName, lastName, birthdate) => {
    try {
      set({ loading: true, error: null });
      
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      
      await updateProfile(userCredential.user, {
        displayName: `${firstName} ${lastName}`
      });
      
      const userProfile = {
        id: userCredential.user.uid,
        firstName,
        lastName,
        email,
        birthdate,
        createdAt: Timestamp.now(),
        updatedAt: Timestamp.now()
      };

      await setDoc(doc(db, 'users', userCredential.user.uid), userProfile);

      set({ currentUser: userProfile, error: null });
    } catch (error) {
      throw handleError(error);
    } finally {
      set({ loading: false });
    }
  },

  login: async (email, password, rememberMe) => {
    try {
      set({ loading: true, error: null });
      
      await setPersistence(auth, rememberMe ? browserLocalPersistence : browserSessionPersistence);
      
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const userDoc = await getDoc(doc(db, 'users', userCredential.user.uid));
      
      if (userDoc.exists()) {
        const userData = userDoc.data();
        set({ currentUser: { ...userData, id: userCredential.user.uid } as UserProfile });
      }
    } catch (error) {
      throw handleError(error);
    } finally {
      set({ loading: false });
    }
  },

  logout: async () => {
    try {
      await signOut(auth);
      set({ currentUser: null });
      window.location.href = ROUTES.HOME;
    } catch (error) {
      throw handleError(error);
    }
  },

  updateUserProfile: async (data) => {
    const { currentUser } = get();
    if (!currentUser) throw new Error('Not authenticated');

    try {
      const userRef = doc(db, 'users', currentUser.id);
      await updateDoc(userRef, {
        ...data,
        updatedAt: Timestamp.now()
      });

      set({ currentUser: { ...currentUser, ...data } });
    } catch (error) {
      throw handleError(error);
    }
  }
});