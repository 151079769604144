import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStore } from '../../store/useStore';
import { Plus, Link, Edit2, Trash2 } from 'lucide-react';
import { useNotification } from '../../hooks/useNotification';
import CountdownTimer from '../CountdownTimer';
import ChatNotification from '../ChatNotification';

interface EventsListProps {
  currentUserId: string;
  onEditEvent: (eventId: string) => void;
}

export default function EventsList({ currentUserId, onEditEvent }: EventsListProps) {
  const navigate = useNavigate();
  const { events = [], deleteEvent } = useStore();
  const { showError, showSuccess } = useNotification();

  const { upcomingEvents, pastEvents } = useMemo(() => {
    const now = new Date();
    const sortedEvents = [...(events || [])].sort((a, b) => {
      const dateA = new Date(`${a.date}T${a.time || '00:00'}`);
      const dateB = new Date(`${b.date}T${b.time || '00:00'}`);
      return dateA.getTime() - dateB.getTime();
    });

    return {
      upcomingEvents: sortedEvents.filter(event => {
        const eventDate = new Date(`${event.date}T${event.time || '00:00'}`);
        return eventDate >= now;
      }),
      pastEvents: sortedEvents.filter(event => {
        const eventDate = new Date(`${event.date}T${event.time || '00:00'}`);
        return eventDate < now;
      }).reverse()
    };
  }, [events]);

  const handleDeleteEvent = async (eventId: string, e: React.MouseEvent) => {
    e.stopPropagation();
    if (window.confirm('Weet je zeker dat je dit evenement wilt verwijderen?')) {
      try {
        await deleteEvent(eventId);
        showSuccess('Evenement verwijderd');
      } catch (error) {
        showError('Er ging iets mis bij het verwijderen van het evenement');
      }
    }
  };

  const getEventProgress = (event: any) => {
    const total = Object.keys(event.participants || {}).length;
    const registered = Object.values(event.participants || {})
      .filter((p: any) => p.confirmed).length;
    const drawnCount = Object.keys(event.drawnNames || {}).length;
    return { total, registered, drawnCount };
  };

  const getUnreadMessageCount = (event: any) => {
    if (!currentUserId) return 0;
    const lastReadTimestamp = event.lastReadTimestamps?.[currentUserId] || 0;
    return (event.messages || []).filter(
      (msg: any) => new Date(msg.timestamp).getTime() > lastReadTimestamp && 
        msg.userId !== currentUserId
    ).length;
  };

  return (
    <div className="space-y-8">
      <div className="flex items-center justify-between mb-6">
        <h2 className="text-2xl font-bold text-gray-900">Aankomende Evenementen</h2>
        <button
          onClick={() => navigate('/create-event')}
          className="bg-warm-olive text-white px-4 py-2 rounded-md hover:bg-cool-olive flex items-center"
        >
          <Plus className="h-5 w-5 mr-2" />
          Nieuw Evenement
        </button>
      </div>

      <div className="space-y-4">
        {upcomingEvents.map((event) => (
          <EventCard
            key={event.id}
            event={event}
            currentUserId={currentUserId}
            onEdit={onEditEvent}
            onDelete={handleDeleteEvent}
            getProgress={getEventProgress}
            getUnreadCount={getUnreadMessageCount}
          />
        ))}

        {upcomingEvents.length === 0 && (
          <p className="text-center text-gray-500 py-4">
            Geen aankomende evenementen
          </p>
        )}
      </div>

      {pastEvents.length > 0 && (
        <div className="space-y-4">
          <h2 className="text-2xl font-bold text-gray-900">Afgelopen Evenementen</h2>
          {pastEvents.map((event) => (
            <EventCard
              key={event.id}
              event={event}
              currentUserId={currentUserId}
              onEdit={onEditEvent}
              onDelete={handleDeleteEvent}
              getProgress={getEventProgress}
              getUnreadCount={getUnreadMessageCount}
            />
          ))}
        </div>
      )}
    </div>
  );
}

interface EventCardProps {
  event: any;
  currentUserId: string;
  onEdit: (id: string) => void;
  onDelete: (id: string, e: React.MouseEvent) => void;
  getProgress: (event: any) => { total: number; registered: number; drawnCount: number };
  getUnreadCount: (event: any) => number;
}

function EventCard({ event, currentUserId, onEdit, onDelete, getProgress, getUnreadCount }: EventCardProps) {
  const navigate = useNavigate();
  const { showSuccess } = useNotification();
  const progress = getProgress(event);
  const unreadMessages = getUnreadCount(event);

  return (
    <div
      key={event.id}
      onClick={() => navigate(`/event/${event.id}`)}
      className="bg-white p-6 rounded-lg shadow-sm hover:shadow-md transition-shadow cursor-pointer"
    >
      <div className="flex justify-between items-start">
        <div className="flex-grow">
          <div className="flex items-center justify-between mb-2">
            <h3 className="text-lg font-medium text-gray-900">{event.name}</h3>
            <CountdownTimer targetDate={event.date} targetTime={event.time} />
          </div>
          <div className="space-y-1">
            <p className="text-sm text-gray-500">
              {progress.registered} van {progress.total} deelnemers geregistreerd
            </p>
            {event.isLootjesEvent && (
              <p className="text-sm text-gray-500">
                {progress.drawnCount > 0
                  ? `${progress.drawnCount} van ${progress.total} lootjes getrokken`
                  : 'Lootjes nog niet getrokken'}
              </p>
            )}
          </div>
        </div>
        <div className="flex flex-col space-y-0.5 ml-4">
          <button
            onClick={(e) => {
              e.stopPropagation();
              const url = `${window.location.origin}/event/${event.id}/welcome`;
              navigator.clipboard.writeText(url);
              showSuccess('Link gekopieerd naar klembord');
            }}
            className="p-1 text-gray-400 hover:text-gray-600"
          >
            <Link className="h-5 w-5" />
          </button>
          {event.organizer === currentUserId && (
            <>
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  onEdit(event.id);
                }}
                className="p-1 text-gray-400 hover:text-gray-600"
              >
                <Edit2 className="h-5 w-5" />
              </button>
              <button
                onClick={(e) => onDelete(event.id, e)}
                className="p-1 text-red-400 hover:text-red-600"
              >
                <Trash2 className="h-5 w-5" />
              </button>
            </>
          )}
          {unreadMessages > 0 && (
            <ChatNotification unreadCount={unreadMessages} />
          )}
        </div>
      </div>
    </div>
  );
}