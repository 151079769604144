import React from 'react';
import { Save, X } from 'lucide-react';
import { EventDetailsData } from '../../types/event';

interface EventDetailsFormProps {
  initialData: EventDetailsData;
  onSave: (data: EventDetailsData) => void;
  onCancel: () => void;
}

export default function EventDetailsForm({
  initialData,
  onSave,
  onCancel
}: EventDetailsFormProps) {
  const [formData, setFormData] = React.useState({
    ...initialData,
    street: initialData.location?.split(',')[0]?.trim() || '',
    houseNumber: initialData.location?.split(',')[1]?.trim() || '',
    postalCode: initialData.location?.split(',')[2]?.trim() || '',
    city: initialData.location?.split(',')[3]?.trim() || ''
  });

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const location = [formData.street, formData.houseNumber, formData.postalCode, formData.city]
      .filter(Boolean)
      .join(', ');
    
    onSave({
      ...formData,
      location
    });
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-3">
      <div>
        <label className="block text-sm font-medium text-gray-700">
          Naam evenement
        </label>
        <input
          type="text"
          value={formData.name}
          onChange={(e) => setFormData({ ...formData, name: e.target.value })}
          className="mt-1 block w-full rounded-md border-2 border-gray-300 shadow-sm focus:border-warm-olive focus:ring-warm-olive"
          required
        />
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Datum
          </label>
          <input
            type="date"
            value={formData.date}
            onChange={(e) => setFormData({ ...formData, date: e.target.value })}
            className="mt-1 block w-full rounded-md border-2 border-gray-300 shadow-sm focus:border-warm-olive focus:ring-warm-olive"
            required
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Starttijd
          </label>
          <input
            type="time"
            value={formData.time || ''}
            onChange={(e) => setFormData({ ...formData, time: e.target.value })}
            className="mt-1 block w-full rounded-md border-2 border-gray-300 shadow-sm focus:border-warm-olive focus:ring-warm-olive"
          />
        </div>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">
          Eindtijd (optioneel)
        </label>
        <input
          type="time"
          value={formData.endTime || ''}
          onChange={(e) => setFormData({ ...formData, endTime: e.target.value })}
          className="mt-1 block w-full rounded-md border-2 border-gray-300 shadow-sm focus:border-warm-olive focus:ring-warm-olive"
        />
      </div>

      <div className="grid grid-cols-2 gap-3">
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Straat
          </label>
          <input
            type="text"
            value={formData.street}
            onChange={(e) => setFormData({ ...formData, street: e.target.value })}
            className="mt-1 block w-full rounded-md border-2 border-gray-300 shadow-sm focus:border-warm-olive focus:ring-warm-olive"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Huisnummer
          </label>
          <input
            type="text"
            value={formData.houseNumber}
            onChange={(e) => setFormData({ ...formData, houseNumber: e.target.value })}
            className="mt-1 block w-full rounded-md border-2 border-gray-300 shadow-sm focus:border-warm-olive focus:ring-warm-olive"
          />
        </div>
      </div>

      <div className="grid grid-cols-2 gap-3">
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Postcode
          </label>
          <input
            type="text"
            value={formData.postalCode}
            onChange={(e) => setFormData({ ...formData, postalCode: e.target.value })}
            className="mt-1 block w-full rounded-md border-2 border-gray-300 shadow-sm focus:border-warm-olive focus:ring-warm-olive"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Plaats
          </label>
          <input
            type="text"
            value={formData.city}
            onChange={(e) => setFormData({ ...formData, city: e.target.value })}
            className="mt-1 block w-full rounded-md border-2 border-gray-300 shadow-sm focus:border-warm-olive focus:ring-warm-olive"
          />
        </div>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">
          Thema (optioneel)
        </label>
        <input
          type="text"
          value={formData.theme || ''}
          onChange={(e) => setFormData({ ...formData, theme: e.target.value })}
          className="mt-1 block w-full rounded-md border-2 border-gray-300 shadow-sm focus:border-warm-olive focus:ring-warm-olive"
          placeholder="Bijvoorbeeld: Jaren '80, Superhelden, etc."
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">
          Extra informatie (optioneel)
        </label>
        <textarea
          value={formData.additionalInfo || ''}
          onChange={(e) => setFormData({ ...formData, additionalInfo: e.target.value })}
          className="mt-1 block w-full rounded-md border-2 border-gray-300 shadow-sm focus:border-warm-olive focus:ring-warm-olive"
          rows={3}
          placeholder="Voeg hier eventuele extra informatie toe..."
        />
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Telefoonnummer
          </label>
          <input
            type="tel"
            value={formData.organizerPhone || ''}
            onChange={(e) => setFormData({ ...formData, organizerPhone: e.target.value })}
            className="mt-1 block w-full rounded-md border-2 border-gray-300 shadow-sm focus:border-warm-olive focus:ring-warm-olive"
            placeholder="+31 6 12345678"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">
            E-mailadres
          </label>
          <input
            type="email"
            value={formData.organizerEmail || ''}
            onChange={(e) => setFormData({ ...formData, organizerEmail: e.target.value })}
            className="mt-1 block w-full rounded-md border-2 border-gray-300 shadow-sm focus:border-warm-olive focus:ring-warm-olive"
            placeholder="naam@voorbeeld.nl"
          />
        </div>
      </div>

      <div className="flex justify-end space-x-3 pt-3">
        <button
          type="button"
          onClick={onCancel}
          className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50 flex items-center"
        >
          <X className="h-5 w-5 mr-2" />
          Annuleren
        </button>
        <button
          type="submit"
          className="px-4 py-2 bg-warm-olive text-white rounded-md hover:bg-cool-olive flex items-center"
        >
          <Save className="h-5 w-5 mr-2" />
          Opslaan
        </button>
      </div>
    </form>
  );
}