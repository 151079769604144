import React, { useState } from 'react';
import { useProductSearch } from '../hooks/useProductSearch';
import SearchBar from '../components/product-search/SearchBar';
import FilterBar from '../components/product-search/FilterBar';
import ProductGrid from '../components/product-search/ProductGrid';
import LoadingSpinner from '../components/LoadingSpinner';
import ProductSearchError from '../components/product-search/ProductSearchError';

export default function ProductSearchPage() {
  const [error, setError] = useState<string | null>(null);
  const {
    searchTerm,
    setSearchTerm,
    selectedFilters,
    products,
    loading,
    handleSearch,
    handleFilterToggle,
    handleAddToWishlist
  } = useProductSearch({
    onError: (message) => setError(message)
  });

  const handleRetry = () => {
    setError(null);
    handleSearch();
  };

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <div className="mb-8">
        <h1 className="text-2xl font-bold text-gray-900">Nieuwe Verlanglijst</h1>
        <p className="mt-2 text-gray-600">
          Zoek producten om toe te voegen aan je verlanglijst
        </p>
      </div>

      <div className="space-y-6">
        <SearchBar
          value={searchTerm}
          onChange={setSearchTerm}
          onSearch={handleSearch}
        />

        <FilterBar
          selectedFilters={selectedFilters}
          onFilterToggle={handleFilterToggle}
        />

        {error ? (
          <ProductSearchError 
            message={error}
            onRetry={handleRetry}
          />
        ) : loading ? (
          <div className="flex justify-center items-center py-12">
            <LoadingSpinner size="lg" />
          </div>
        ) : (
          <ProductGrid
            products={products}
            onProductSelect={handleAddToWishlist}
          />
        )}
      </div>
    </div>
  );
}