import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useAuth } from '../components/AuthContext';
import { useStore } from '../store/useStore';
import { authService } from '../services/auth';

export const useRequireAuth = () => {
  const { currentUser } = useStore();
  const { showLoginModal } = useAuth();
  const [isChecking, setIsChecking] = useState(true);
  const location = useLocation();

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const firebaseUser = authService.getCurrentUser();
        
        // Only show login modal if there's no Firebase user and no store user
        // and we're not on the home page or welcome pages
        if (!firebaseUser && !currentUser && 
            location.pathname !== '/' && 
            !location.pathname.includes('/welcome') &&
            !location.pathname.includes('/search')) {
          showLoginModal();
        }
      } finally {
        setIsChecking(false);
      }
    };

    checkAuth();
  }, [currentUser, showLoginModal, location]);

  if (isChecking) {
    return null;
  }

  return currentUser;
};