import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Search as SearchIcon } from 'lucide-react';
import { collection, query, where, getDocs, and } from 'firebase/firestore';
import { db } from '../config/firebase';
import { toast } from 'react-toastify';
import UserAvatar from '../components/UserAvatar';
import LoadingSpinner from '../components/LoadingSpinner';
import WishlistInviteHandler from '../components/WishlistInviteHandler';

// Remove auth requirement and keep the rest of the code the same...
// (Keep all existing code below this point)

interface SearchResult {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  photoURL?: string;
  address?: {
    city?: string;
  };
  wishlists: {
    id: string;
    name: string;
    itemCount: number;
  }[];
}

export default function SearchPage() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    city: ''
  });
  const [results, setResults] = useState<SearchResult[]>([]);
  const [isSearching, setIsSearching] = useState(false);
  const [hasSearched, setHasSearched] = useState(false);
  const [searchedFirstName, setSearchedFirstName] = useState('');

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!formData.firstName.trim() || !formData.lastName.trim()) {
      toast.error('Vul voor- en achternaam in');
      return;
    }

    setSearchedFirstName(formData.firstName.trim());
    setIsSearching(true);
    setHasSearched(true);

    try {
      const usersRef = collection(db, 'users');
      let conditions = [];

      if (formData.firstName) {
        conditions.push(where('firstName_lower', '==', formData.firstName.toLowerCase()));
      }
      if (formData.lastName) {
        conditions.push(where('lastName_lower', '==', formData.lastName.toLowerCase()));
      }
      if (formData.email) {
        conditions.push(where('email', '==', formData.email.toLowerCase()));
      }
      if (formData.city) {
        conditions.push(where('address.city_lower', '==', formData.city.toLowerCase()));
      }

      const userQuery = query(usersRef, and(...conditions));
      const userSnapshot = await getDocs(userQuery);
      const userResults: SearchResult[] = [];

      for (const userDoc of userSnapshot.docs) {
        const userData = userDoc.data();
        const wishlistsRef = collection(db, 'wishlists');
        const wishlistQuery = query(
          wishlistsRef,
          where('userId', '==', userDoc.id),
          where('isPrivate', '==', false)
        );
        const wishlistSnapshot = await getDocs(wishlistQuery);

        const wishlists = wishlistSnapshot.docs.map(doc => ({
          id: doc.id,
          name: doc.data().name,
          itemCount: doc.data().items?.length || 0
        }));

        userResults.push({
          id: userDoc.id,
          firstName: userData.firstName,
          lastName: userData.lastName,
          email: userData.email,
          photoURL: userData.photoURL,
          address: userData.address,
          wishlists
        });
      }

      setResults(userResults);
    } catch (error) {
      console.error('Search error:', error);
      toast.error('Er ging iets mis bij het zoeken');
    } finally {
      setIsSearching(false);
    }
  };

  const handleViewWishlist = (wishlistId: string) => {
    navigate(`/wishlist/${wishlistId}`);
  };

  return (
    <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <form onSubmit={handleSubmit} className="space-y-4">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Voornaam *
            </label>
            <input
              type="text"
              value={formData.firstName}
              onChange={(e) => setFormData({ ...formData, firstName: e.target.value })}
              className="mt-1 block w-full rounded-md border-2 border-gray-300 shadow-sm focus:border-warm-olive focus:ring-warm-olive"
              required
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Achternaam *
            </label>
            <input
              type="text"
              value={formData.lastName}
              onChange={(e) => setFormData({ ...formData, lastName: e.target.value })}
              className="mt-1 block w-full rounded-md border-2 border-gray-300 shadow-sm focus:border-warm-olive focus:ring-warm-olive"
              required
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              E-mailadres
            </label>
            <input
              type="email"
              value={formData.email}
              onChange={(e) => setFormData({ ...formData, email: e.target.value })}
              className="mt-1 block w-full rounded-md border-2 border-gray-300 shadow-sm focus:border-warm-olive focus:ring-warm-olive"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Woonplaats
            </label>
            <input
              type="text"
              value={formData.city}
              onChange={(e) => setFormData({ ...formData, city: e.target.value })}
              className="mt-1 block w-full rounded-md border-2 border-gray-300 shadow-sm focus:border-warm-olive focus:ring-warm-olive"
            />
          </div>
        </div>
        <div className="flex justify-between">
          <button
            type="button"
            onClick={() => setFormData({ firstName: '', lastName: '', email: '', city: '' })}
            className="px-4 py-2 text-gray-700 hover:text-gray-900"
          >
            Wis zoekopdracht
          </button>
          <button
            type="submit"
            className="bg-warm-olive text-white px-6 py-2 rounded-md hover:bg-cool-olive flex items-center"
          >
            <SearchIcon className="h-5 w-5 mr-2" />
            Zoeken
          </button>
        </div>
      </form>

      {isSearching ? (
        <div className="flex justify-center items-center py-12">
          <LoadingSpinner size="lg" />
        </div>
      ) : (
        hasSearched && (
          <div className="mt-8">
            {results.length > 0 ? (
              <div className="space-y-6">
                {results.map((result) => (
                  <div key={result.id} className="bg-white rounded-lg shadow-sm p-6">
                    <div className="flex items-start space-x-4">
                      <UserAvatar
                        firstName={result.firstName}
                        lastName={result.lastName}
                        photoURL={result.photoURL}
                        size="lg"
                      />
                      <div className="flex-grow">
                        <h3 className="text-lg font-medium text-gray-900">
                          {result.firstName} {result.lastName}
                        </h3>
                        {result.address?.city && (
                          <p className="text-sm text-gray-500">
                            {result.address.city}
                          </p>
                        )}
                        {result.wishlists.length > 0 ? (
                          <div className="mt-4 space-y-2">
                            {result.wishlists.map((wishlist) => (
                              <div
                                key={wishlist.id}
                                onClick={() => handleViewWishlist(wishlist.id)}
                                className="flex items-center justify-between p-3 bg-gray-50 rounded-md cursor-pointer hover:bg-gray-100"
                              >
                                <div>
                                  <h4 className="font-medium text-gray-900">{wishlist.name}</h4>
                                  <p className="text-sm text-gray-500">
                                    {wishlist.itemCount} items
                                  </p>
                                </div>
                                <button className="text-warm-olive hover:text-cool-olive text-sm font-medium">
                                  Bekijk lijst
                                </button>
                              </div>
                            ))}
                          </div>
                        ) : (
                          <WishlistInviteHandler
                            recipientFirstName={result.firstName}
                            recipientLastName={result.lastName}
                            recipientEmail={result.email}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="text-center py-12">
                <p className="text-gray-500 mb-4">Geen resultaten gevonden</p>
                {/* Verwijderde verwijzing naar de login popup */}
                {/* WishlistInviteHandler kan hier nog steeds worden weergegeven */}
                <WishlistInviteHandler
                  recipientFirstName={searchedFirstName}
                  recipientLastName={formData.lastName}
                />
              </div>
            )}
          </div>
        )
      )}
    </div>
  );
}