import React, { useState } from 'react';
import { X } from 'lucide-react';
import { useStore } from '../store/useStore';
import { toast } from 'react-toastify';
import RequiredFieldMarker from './RequiredFieldMarker';
import DateInput from './DateInput';
import { validateBirthdate } from '../utils/validation';

interface RegisterModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSwitchToLogin: () => void;
}

export default function RegisterModal({ isOpen, onClose, onSwitchToLogin }: RegisterModalProps) {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: '',
    birthdate: new Date().toISOString().split('T')[0]
  });
  const [birthdateError, setBirthdateError] = useState<string>();
  const { register, loading, error } = useStore();

  if (!isOpen) return null;

  const handleEmailRegister = async (e: React.FormEvent) => {
    e.preventDefault();
    if (formData.password !== formData.confirmPassword) {
      toast.error('Wachtwoorden komen niet overeen');
      return;
    }

    const birthdateValidation = validateBirthdate(formData.birthdate);
    if (!birthdateValidation.isValid) {
      setBirthdateError(birthdateValidation.message);
      return;
    }

    try {
      await register(
        formData.email,
        formData.password,
        formData.firstName,
        formData.lastName,
        formData.birthdate
      );
      onClose();
    } catch (error) {
      // Error is handled by the store
    }
  };

  const handleBirthdateChange = (value: string) => {
    setFormData({ ...formData, birthdate: value });
    setBirthdateError(undefined);
  };

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="flex min-h-full items-center justify-center p-4">
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75" onClick={onClose} />
        
        <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-md">
          <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-semibold text-gray-900">Registreren</h2>
              <button onClick={onClose} className="text-gray-400 hover:text-gray-500">
                <X className="h-6 w-6" />
              </button>
            </div>

            <form onSubmit={handleEmailRegister} className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Voornaam<RequiredFieldMarker />
                </label>
                <input
                  type="text"
                  required
                  value={formData.firstName}
                  onChange={(e) => setFormData({ ...formData, firstName: e.target.value })}
                  className="mt-1 block w-full rounded-md border-2 border-gray-300 shadow-sm focus:border-warm-olive focus:ring-warm-olive"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Achternaam<RequiredFieldMarker />
                </label>
                <input
                  type="text"
                  required
                  value={formData.lastName}
                  onChange={(e) => setFormData({ ...formData, lastName: e.target.value })}
                  className="mt-1 block w-full rounded-md border-2 border-gray-300 shadow-sm focus:border-warm-olive focus:ring-warm-olive"
                />
              </div>

              <DateInput
                label="Geboortedatum"
                value={formData.birthdate}
                onChange={handleBirthdateChange}
                error={birthdateError}
                required
                maxDate={new Date().toISOString().split('T')[0]}
              />

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  E-mail<RequiredFieldMarker />
                </label>
                <input
                  type="email"
                  required
                  value={formData.email}
                  onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                  className="mt-1 block w-full rounded-md border-2 border-gray-300 shadow-sm focus:border-warm-olive focus:ring-warm-olive"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Wachtwoord<RequiredFieldMarker />
                </label>
                <input
                  type="password"
                  required
                  value={formData.password}
                  onChange={(e) => setFormData({ ...formData, password: e.target.value })}
                  className="mt-1 block w-full rounded-md border-2 border-gray-300 shadow-sm focus:border-warm-olive focus:ring-warm-olive"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Bevestig wachtwoord<RequiredFieldMarker />
                </label>
                <input
                  type="password"
                  required
                  value={formData.confirmPassword}
                  onChange={(e) => setFormData({ ...formData, confirmPassword: e.target.value })}
                  className="mt-1 block w-full rounded-md border-2 border-gray-300 shadow-sm focus:border-warm-olive focus:ring-warm-olive"
                />
              </div>

              {error && (
                <p className="text-sm text-red-600">{error}</p>
              )}

              <button
                type="submit"
                disabled={loading}
                className="w-full bg-warm-olive text-white px-4 py-2 rounded-md hover:bg-cool-olive disabled:opacity-50"
              >
                {loading ? 'Bezig met registreren...' : 'Registreren'}
              </button>
            </form>

            <div className="text-center mt-4">
              <button
                type="button"
                onClick={onSwitchToLogin}
                className="text-sm text-warm-olive hover:text-cool-olive"
              >
                Al een account? Log in
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}