export const validateBirthdate = (birthdate: string): { isValid: boolean; message?: string } => {
  if (!birthdate) {
    return { isValid: false, message: 'Geboortedatum is verplicht' };
  }

  const birthdateDate = new Date(birthdate);
  const today = new Date();
  
  // Check if date is valid
  if (isNaN(birthdateDate.getTime())) {
    return { isValid: false, message: 'Ongeldige datum' };
  }

  // Check if date is in the future
  if (birthdateDate > today) {
    return { isValid: false, message: 'Geboortedatum kan niet in de toekomst liggen' };
  }

  // Calculate age
  let age = today.getFullYear() - birthdateDate.getFullYear();
  const monthDiff = today.getMonth() - birthdateDate.getMonth();
  
  if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthdateDate.getDate())) {
    age--;
  }

  // Check minimum age
  if (age < 13) {
    return { isValid: false, message: 'Je moet minimaal 13 jaar oud zijn' };
  }

  return { isValid: true };
};

export const formatDate = (date: string): string => {
  return new Date(date).toLocaleDateString('nl-NL', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric'
  });
};