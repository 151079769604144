import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useStore } from '../store/useStore';
import { Calendar, MapPin, Clock, Users } from 'lucide-react';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../config/firebase';
import ParticipantSelectionModal from '../components/ParticipantSelectionModal';

export default function EventWelcomePage() {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { currentUser } = useStore();
  const [event, setEvent] = React.useState<any>(null);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    const fetchEvent = async () => {
      if (!id) return;
      
      try {
        const eventDoc = await getDoc(doc(db, 'events', id));
        if (eventDoc.exists()) {
          setEvent({ id: eventDoc.id, ...eventDoc.data() });
        }
      } catch (error) {
        console.error('Error fetching event:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchEvent();
  }, [id]);

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-warm-olive"></div>
      </div>
    );
  }

  if (!event) {
    return (
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="bg-white rounded-lg shadow-sm p-6 text-center">
          <p className="text-gray-600">Evenement niet gevonden.</p>
          <button
            onClick={() => navigate('/')}
            className="mt-4 text-warm-olive hover:text-cool-olive"
          >
            Terug naar Home
          </button>
        </div>
      </div>
    );
  }

  // If user is logged in and is a participant, redirect to event page
  if (currentUser && event.participants[currentUser.id]) {
    navigate(`/event/${event.id}`);
    return null;
  }

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="max-w-3xl mx-auto px-4 py-12">
        <div className="bg-white rounded-lg shadow-sm overflow-hidden">
          <div className="bg-warm-olive text-white p-6">
            <h1 className="text-2xl font-bold mb-2">{event.name}</h1>
            <p className="opacity-90">Je bent uitgenodigd om deel te nemen!</p>
          </div>

          <div className="p-6 space-y-6">
            <div className="space-y-4">
              <div className="flex items-center text-gray-600">
                <Calendar className="h-5 w-5 mr-3" />
                <span>
                  {new Date(event.date).toLocaleDateString('nl-NL', {
                    weekday: 'long',
                    day: 'numeric',
                    month: 'long',
                    year: 'numeric'
                  })}
                  {event.time && ` om ${event.time}`}
                </span>
              </div>

              {event.location && (
                <div className="flex items-center text-gray-600">
                  <MapPin className="h-5 w-5 mr-3" />
                  <span>{event.location}</span>
                </div>
              )}

              {event.theme && (
                <div className="flex items-center text-gray-600">
                  <Clock className="h-5 w-5 mr-3" />
                  <span>{event.theme}</span>
                </div>
              )}

              <div className="flex items-center text-gray-600">
                <Users className="h-5 w-5 mr-3" />
                <span>{Object.keys(event.participants).length} deelnemers</span>
              </div>
            </div>

            <div className="border-t border-gray-200 pt-6">
              <ParticipantSelectionModal 
                event={event}
                onClose={() => navigate('/')}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}