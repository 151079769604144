import React from 'react';
import { AlertTriangle } from 'lucide-react';

interface ProductSearchErrorProps {
  message: string;
  onRetry: () => void;
}

export default function ProductSearchError({ message, onRetry }: ProductSearchErrorProps) {
  return (
    <div className="text-center py-12">
      <AlertTriangle className="h-12 w-12 text-red-500 mx-auto mb-4" />
      <p className="text-gray-700 mb-4">{message}</p>
      <button
        onClick={onRetry}
        className="px-4 py-2 bg-warm-olive text-white rounded-md hover:bg-cool-olive"
      >
        Probeer opnieuw
      </button>
    </div>
  );
}