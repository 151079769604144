import React from 'react';
import { Link } from 'react-router-dom';

export default function Footer() {
  return (
    <footer className="bg-white border-t border-gray-200 mt-auto">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6">
        <div className="text-center">
          <Link
            to="/privacy"
            className="text-gray-600 hover:text-warm-olive transition-colors"
          >
            Privacy
          </Link>
        </div>
      </div>
    </footer>
  );
}