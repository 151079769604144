import { create } from 'zustand';
import { createAuthSlice, AuthSlice } from './slices/authSlice';
import { createEventSlice, EventSlice } from './slices/eventSlice';
import { createWishlistSlice, WishlistSlice } from './slices/wishlistSlice';
import { subscribeToAuthState } from '../utils/auth';

type Store = AuthSlice & EventSlice & WishlistSlice;

export const useStore = create<Store>()((set, get) => {
  const store = {
    ...createAuthSlice(set, get),
    ...createEventSlice(set, get),
    ...createWishlistSlice(set, get)
  };

  // Initialize auth subscription
  subscribeToAuthState(store);

  return store;
});