import React, { useState } from 'react';
import { useStore } from '../store/useStore';
import { toast } from 'react-toastify';
import { useRequireAuth } from '../hooks/useRequireAuth';
import PhotoSection from '../components/profile/PhotoSection';
import PersonalInfoSection from '../components/profile/PersonalInfoSection';
import AddressSection from '../components/profile/AddressSection';
import PasswordChangeSection from '../components/PasswordChangeSection';

interface FormData {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  birthdate: string;
  photoURL?: string;
  address: {
    street: string;
    number: string;
    box: string;
    postalCode: string;
    city: string;
  };
}

export default function ProfilePage() {
  const { currentUser, updateUserProfile } = useStore();
  const [isSubmitting, setIsSubmitting] = useState({
    photo: false,
    personal: false,
    address: false
  });
  
  const user = useRequireAuth();

  const [formData, setFormData] = useState<FormData>({
    firstName: currentUser?.firstName || '',
    lastName: currentUser?.lastName || '',
    email: currentUser?.email || '',
    phone: currentUser?.phone || '',
    birthdate: currentUser?.birthdate || '',
    photoURL: currentUser?.photoURL,
    address: currentUser?.address || {
      street: '',
      number: '',
      box: '',
      postalCode: '',
      city: ''
    }
  });

  if (!user) {
    return null;
  }

  const handlePhotoSubmit = async () => {
    try {
      setIsSubmitting(prev => ({ ...prev, photo: true }));
      await updateUserProfile({ photoURL: formData.photoURL });
      toast.success('Profielfoto bijgewerkt');
    } catch (error: any) {
      toast.error(error.message || 'Er is een fout opgetreden');
    } finally {
      setIsSubmitting(prev => ({ ...prev, photo: false }));
    }
  };

  const handlePersonalInfoSubmit = async () => {
    if (!formData.firstName.trim() || !formData.lastName.trim() || !formData.email.trim() || !formData.birthdate) {
      toast.error('Vul alle verplichte velden in');
      return;
    }

    try {
      setIsSubmitting(prev => ({ ...prev, personal: true }));
      await updateUserProfile({
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email,
        phone: formData.phone,
        birthdate: formData.birthdate
      });
      toast.success('Persoonlijke informatie bijgewerkt');
    } catch (error: any) {
      toast.error(error.message || 'Er is een fout opgetreden');
    } finally {
      setIsSubmitting(prev => ({ ...prev, personal: false }));
    }
  };

  const handleAddressSubmit = async () => {
    try {
      setIsSubmitting(prev => ({ ...prev, address: true }));
      await updateUserProfile({ address: formData.address });
      toast.success('Adres bijgewerkt');
    } catch (error: any) {
      toast.error(error.message || 'Er is een fout opgetreden');
    } finally {
      setIsSubmitting(prev => ({ ...prev, address: false }));
    }
  };

  const handlePersonalInfoChange = (field: keyof Pick<FormData, 'firstName' | 'lastName' | 'email' | 'phone' | 'birthdate'>, value: string) => {
    setFormData(prev => ({ ...prev, [field]: value }));
  };

  const handleAddressChange = (field: keyof FormData['address'], value: string) => {
    setFormData(prev => ({
      ...prev,
      address: { ...prev.address, [field]: value }
    }));
  };

  return (
    <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 py-6">
      <h1 className="text-2xl font-bold text-gray-900 mb-6">Mijn Profiel</h1>

      <div className="space-y-4">
        <div>
          <PhotoSection
            photoURL={formData.photoURL}
            onPhotoChange={(url) => setFormData(prev => ({ ...prev, photoURL: url }))}
          />
          <div className="flex justify-end mt-3">
            <button
              onClick={handlePhotoSubmit}
              disabled={isSubmitting.photo}
              className="bg-warm-olive text-white px-4 py-2 rounded-md hover:bg-cool-olive disabled:opacity-50"
            >
              {isSubmitting.photo ? 'Bezig met opslaan...' : 'Wijzigingen Opslaan'}
            </button>
          </div>
        </div>

        <div>
          <PersonalInfoSection
            data={{
              firstName: formData.firstName,
              lastName: formData.lastName,
              email: formData.email,
              phone: formData.phone,
              birthdate: formData.birthdate
            }}
            onChange={handlePersonalInfoChange}
          />
          <div className="flex justify-end mt-3">
            <button
              onClick={handlePersonalInfoSubmit}
              disabled={isSubmitting.personal}
              className="bg-warm-olive text-white px-4 py-2 rounded-md hover:bg-cool-olive disabled:opacity-50"
            >
              {isSubmitting.personal ? 'Bezig met opslaan...' : 'Wijzigingen Opslaan'}
            </button>
          </div>
        </div>

        <div>
          <AddressSection
            address={formData.address}
            onChange={handleAddressChange}
          />
          <div className="flex justify-end mt-3">
            <button
              onClick={handleAddressSubmit}
              disabled={isSubmitting.address}
              className="bg-warm-olive text-white px-4 py-2 rounded-md hover:bg-cool-olive disabled:opacity-50"
            >
              {isSubmitting.address ? 'Bezig met opslaan...' : 'Wijzigingen Opslaan'}
            </button>
          </div>
        </div>

        <PasswordChangeSection />
      </div>
    </div>
  );
}