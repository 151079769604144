import React from 'react';
import Logo from '../components/Logo';

export default function LogoDownloadPage() {
  return (
    <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <div className="text-center">
        <h1 className="text-3xl font-bold text-gray-900 mb-8">Download Logo</h1>
        <div className="bg-white rounded-lg shadow-sm p-8">
          <Logo size="xl" />
          <p className="mt-8 text-gray-600">
            Download het Wish2Share logo in hoge resolutie voor gebruik in uw materialen.
            Beschikbaar in SVG (vector) en PNG (512x512 pixels) formaat.
          </p>
        </div>
      </div>
    </div>
  );
}