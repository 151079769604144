import React from 'react';
import { AmazonProduct } from '../../types/amazon';
import ProductCard from './ProductCard';
import EmptyState from './EmptyState';

interface ProductGridProps {
  products: AmazonProduct[];
  onProductSelect: (product: AmazonProduct, wishlistName: string) => Promise<void>;
}

export default function ProductGrid({ products, onProductSelect }: ProductGridProps) {
  if (products.length === 0) {
    return <EmptyState />;
  }

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
      {products.map((product) => (
        <ProductCard
          key={product.ASIN}
          product={product}
          onSelect={onProductSelect}
        />
      ))}
    </div>
  );
}