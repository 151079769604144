import React from 'react';

const inputClasses = "mt-1 block w-full rounded-md border-2 border-gray-300 shadow-sm focus:border-warm-olive focus:ring-warm-olive";

interface Address {
  street: string;
  number: string;
  box: string;
  postalCode: string;
  city: string;
}

interface AddressSectionProps {
  address: Address;
  onChange: (field: keyof Address, value: string) => void;
}

export default function AddressSection({ address, onChange }: AddressSectionProps) {
  return (
    <div className="bg-white shadow rounded-lg p-4">
      <h2 className="text-lg font-semibold text-gray-900 mb-3">Adres</h2>
      <div className="grid grid-cols-1 gap-3 sm:grid-cols-2">
        <div className="sm:col-span-2">
          <label className="block text-sm font-medium text-gray-700">Straat</label>
          <input
            type="text"
            value={address.street}
            onChange={(e) => onChange('street', e.target.value)}
            className={inputClasses}
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">Nummer</label>
          <input
            type="text"
            value={address.number}
            onChange={(e) => onChange('number', e.target.value)}
            className={inputClasses}
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">Bus</label>
          <input
            type="text"
            value={address.box}
            onChange={(e) => onChange('box', e.target.value)}
            className={inputClasses}
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">Postcode</label>
          <input
            type="text"
            value={address.postalCode}
            onChange={(e) => onChange('postalCode', e.target.value)}
            className={inputClasses}
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">Plaats</label>
          <input
            type="text"
            value={address.city}
            onChange={(e) => onChange('city', e.target.value)}
            className={inputClasses}
          />
        </div>
      </div>
    </div>
  );
}