import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { AppRoutes } from './routes/index';
import { AuthProvider } from './components/AuthContext';
import BackgroundPattern from './components/BackgroundPattern';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import NotificationContainer from './components/notifications/NotificationContainer';

export default function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <BackgroundPattern>
          <div className="min-h-screen flex flex-col">
            <Navbar />
            <div className="flex-grow">
              <AppRoutes />
            </div>
            <Footer />
            <NotificationContainer />
          </div>
        </BackgroundPattern>
      </AuthProvider>
    </BrowserRouter>
  );
}