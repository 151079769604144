import React, { useState, useRef } from 'react';
import { Send, Smile, Image as GifIcon } from 'lucide-react';
import EmojiPicker, { EmojiClickData } from 'emoji-picker-react';
import { IGif } from '@giphy/js-types';
import { useClickOutside } from '../../hooks/useClickOutside';
import AutoGrowTextarea from './AutoGrowTextarea';
import GifPicker from './GifPicker';

interface ChatInputProps {
  onSendMessage: (text: string, isAnonymous: boolean, gifUrl?: string) => Promise<void>;
}

export default function ChatInput({ onSendMessage }: ChatInputProps) {
  const [message, setMessage] = useState('');
  const [isAnonymous, setIsAnonymous] = useState(false);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [showGifPicker, setShowGifPicker] = useState(false);
  
  const emojiButtonRef = useRef<HTMLButtonElement>(null);
  const emojiPickerRef = useRef<HTMLDivElement>(null);
  const gifButtonRef = useRef<HTMLButtonElement>(null);
  const gifPickerRef = useRef<HTMLDivElement>(null);

  useClickOutside([emojiButtonRef, emojiPickerRef], () => {
    setShowEmojiPicker(false);
  });

  useClickOutside([gifButtonRef, gifPickerRef], () => {
    setShowGifPicker(false);
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!message.trim()) return;

    try {
      const formattedMessage = message.charAt(0).toUpperCase() + message.slice(1);
      await onSendMessage(formattedMessage, isAnonymous);
      setMessage('');
    } catch (error) {
      console.error('Failed to send message:', error);
    }
  };

  const onEmojiClick = (emojiData: EmojiClickData) => {
    setMessage(prev => prev + emojiData.emoji);
    setShowEmojiPicker(false);
  };

  const onGifSelect = async (gif: IGif) => {
    try {
      const gifUrl = gif.images.original.url;
      await onSendMessage('', isAnonymous, gifUrl);
      setShowGifPicker(false);
    } catch (error) {
      console.error('Failed to send GIF:', error);
    }
  };

  return (
    <div className="p-4 bg-white border-t border-gray-200">
      {/* Controls Row */}
      <div className="flex items-center justify-between mb-4 pb-2 border-b border-gray-100">
        {/* Anonymous Toggle */}
        <label className="relative inline-flex items-center cursor-pointer">
          <input
            type="checkbox"
            className="sr-only peer"
            checked={isAnonymous}
            onChange={(e) => setIsAnonymous(e.target.checked)}
          />
          <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-warm-olive/20 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-warm-olive"></div>
          <span className="ms-3 text-sm font-medium text-gray-700">
            Verstuur anoniem
          </span>
        </label>

        {/* Picker Buttons */}
        <div className="flex items-center space-x-2">
          {/* GIF Button */}
          <div className="relative">
            <button
              type="button"
              ref={gifButtonRef}
              onClick={() => {
                setShowGifPicker(!showGifPicker);
                setShowEmojiPicker(false);
              }}
              className="p-2 text-gray-400 hover:text-gray-600 bg-gray-100 rounded-lg transition-colors"
            >
              <GifIcon className="h-5 w-5" />
            </button>

            {/* GIF Picker Popup */}
            {showGifPicker && (
              <div 
                ref={gifPickerRef}
                className="absolute bottom-full right-0 mb-2 z-50"
                style={{ minWidth: '320px' }}
              >
                <GifPicker
                  onGifSelect={onGifSelect}
                  onClose={() => setShowGifPicker(false)}
                />
              </div>
            )}
          </div>

          {/* Emoji Button */}
          <div className="relative">
            <button
              type="button"
              ref={emojiButtonRef}
              onClick={() => {
                setShowEmojiPicker(!showEmojiPicker);
                setShowGifPicker(false);
              }}
              className="p-2 text-gray-400 hover:text-gray-600 bg-gray-100 rounded-lg transition-colors"
            >
              <Smile className="h-5 w-5" />
            </button>

            {/* Emoji Picker Popup */}
            {showEmojiPicker && (
              <div 
                ref={emojiPickerRef}
                className="absolute bottom-full right-0 mb-2 z-50"
              >
                <EmojiPicker
                  onEmojiClick={onEmojiClick}
                  autoFocusSearch={false}
                  width={300}
                  height={400}
                />
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Message Input */}
      <form onSubmit={handleSubmit} className="flex items-center space-x-2">
        <div className="relative flex-1">
          <AutoGrowTextarea
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            placeholder="Schrijf een bericht..."
          />
        </div>
        <button
          type="submit"
          className="bg-warm-olive text-white p-2 rounded-lg hover:bg-cool-olive transition-colors"
        >
          <Send className="h-5 w-5" />
        </button>
      </form>
    </div>
  );
}