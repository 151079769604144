import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useStore } from '../store/useStore';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../config/firebase';
import { toast } from 'react-toastify';
import { ErrorBoundary } from 'react-error-boundary';
import EventDetails from '../components/EventDetails';
import DrawnNameSection from '../components/DrawnNameSection';
import WishlistsSection from '../components/WishlistsSection';
import GroupChat from '../components/GroupChat';
import LoadingSpinner from '../components/LoadingSpinner';
import ErrorFallback from '../components/ErrorFallback';
import PartyPrepsSection from '../components/PartyPreps/PartyPrepsSection';
import WishlistLinkModal from '../components/WishlistLinkModal';
import { useEventMessages } from '../hooks/useEventMessages';
import { useEventParticipants } from '../hooks/useEventParticipants';
import { useMediaQuery } from '../hooks/useMediaQuery';
import { Event, EventDetailsData } from '../types/event';

const EventPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { events, currentUser, updateEvent } = useStore();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const [event, setEvent] = useState<Event | null>(null);
  const [showWishlistModal, setShowWishlistModal] = useState(false);
  const [selectedParticipantId, setSelectedParticipantId] = useState<string>('');
  const isMobile = useMediaQuery('(max-width: 1023px)');

  useEffect(() => {
    const fetchEvent = async () => {
      if (!id) return;
      
      try {
        // First check store
        const storeEvent = events.find(e => e.id === id);
        if (storeEvent) {
          setEvent(storeEvent);
          setLoading(false);
          return;
        }

        // If not in store, fetch from Firestore
        const eventDoc = await getDoc(doc(db, 'events', id));
        if (eventDoc.exists()) {
          const eventData = { id: eventDoc.id, ...eventDoc.data() } as Event;
          setEvent(eventData);

          // If user is logged in but not a participant, redirect to welcome page
          if (currentUser && !eventData.participants[currentUser.id]) {
            navigate(`/event/${id}/welcome`);
            return;
          }
        }
      } catch (error) {
        console.error('Error fetching event:', error);
        setError(error as Error);
      } finally {
        setLoading(false);
      }
    };

    fetchEvent();
  }, [id, events, currentUser, navigate]);

  // If not logged in, redirect to welcome page
  useEffect(() => {
    if (!loading && event && !currentUser) {
      navigate(`/event/${id}/welcome`);
    }
  }, [loading, event, currentUser, id, navigate]);

  const { participants } = useEventParticipants(event);
  const { 
    handleSendMessage, 
    handleEditMessage, 
    handleDeleteMessage 
  } = useEventMessages(event, currentUser);

  const handleUpdateEventDetails = async (data: Partial<EventDetailsData>) => {
    if (!event || !id) return;

    try {
      await updateEvent(id, data);
      toast.success('Evenement bijgewerkt');
    } catch (error) {
      console.error('Error updating event details:', error);
      toast.error('Er ging iets mis bij het bijwerken van het evenement');
      throw error;
    }
  };

  const handleNameDrawn = async (drawnName: string) => {
    try {
      const participant = participants.find(
        p => `${p.firstName} ${p.lastName}` === drawnName
      );
      
      if (!participant) {
        throw new Error('Deelnemer niet gevonden');
      }

      await updateEvent(event!.id, {
        drawnNames: {
          ...event!.drawnNames,
          [currentUser!.id]: participant.id
        }
      });

      toast.success(`Je hebt ${drawnName} getrokken!`);
    } catch (error) {
      toast.error('Er ging iets mis bij het trekken van een naam');
    }
  };

  const getDrawnName = () => {
    if (!event?.drawnNames?.[currentUser!.id]) return undefined;
    
    const drawnParticipant = participants.find(
      p => p.id === event.drawnNames[currentUser!.id]
    );

    return drawnParticipant 
      ? `${drawnParticipant.firstName} ${drawnParticipant.lastName}`
      : undefined;
  };

  const handleWishlistSelect = (participantId: string) => {
    setSelectedParticipantId(participantId);
    setShowWishlistModal(true);
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <LoadingSpinner size="lg" />
      </div>
    );
  }

  if (error) {
    return <ErrorFallback error={error} />;
  }

  if (!event) {
    return (
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="bg-white rounded-lg shadow-sm p-6 text-center">
          <p className="text-gray-600">Evenement niet gevonden.</p>
          <button
            onClick={() => navigate('/')}
            className="mt-4 text-warm-olive hover:text-cool-olive"
          >
            Terug naar Home
          </button>
        </div>
      </div>
    );
  }

  // Render mobile or desktop layout
  const renderContent = () => {
    const sections = [
      <EventDetails
        key="details"
        name={event.name}
        date={event.date}
        time={event.time}
        endTime={event.endTime}
        budget={event.budget}
        location={event.location}
        theme={event.theme}
        additionalInfo={event.additionalInfo}
        organizerPhone={event.organizerPhone}
        organizerEmail={event.organizerEmail}
        isOrganizer={event.organizer === currentUser?.id}
        onUpdate={handleUpdateEventDetails}
      />,
      <DrawnNameSection
        key="drawn-name"
        drawnName={getDrawnName()}
        drawnParticipantId={event.drawnNames?.[currentUser!.id]}
        currentUserName={`${currentUser?.firstName} ${currentUser?.lastName}`}
        participants={participants}
        onNameDrawn={handleNameDrawn}
        eventId={event.id}
      />,
      <WishlistsSection
        key="wishlists"
        participants={participants}
        currentUserId={currentUser?.id || ''}
        eventId={event.id}
        onWishlistSelect={handleWishlistSelect}
      />,
      <PartyPrepsSection
        key="party-preps"
        eventId={event.id}
        isOrganizer={event.organizer === currentUser?.id}
        participants={participants}
        currentUserId={currentUser?.id || ''}
      />,
      <GroupChat
        key="chat"
        eventId={event.id}
        messages={event.messages || []}
        onSendMessage={handleSendMessage}
        onEditMessage={handleEditMessage}
        onDeleteMessage={handleDeleteMessage}
        currentUserId={currentUser?.id || ''}
        currentUserName={`${currentUser?.firstName} ${currentUser?.lastName}`}
      />
    ];

    if (isMobile) {
      return (
        <div className="space-y-3">
          {sections}
        </div>
      );
    }

    return (
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-3">
        <div className="lg:col-span-1 space-y-3">
          {sections.slice(0, 2)}
        </div>
        <div className="lg:col-span-1 space-y-3">
          {sections.slice(2, 4)}
        </div>
        <div className="lg:col-span-1">
          {sections[4]}
        </div>
      </div>
    );
  };

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4">
        {renderContent()}
        {showWishlistModal && (
          <WishlistLinkModal
            isOpen={showWishlistModal}
            onClose={() => setShowWishlistModal(false)}
            eventId={event.id}
            participantId={selectedParticipantId}
          />
        )}
      </div>
    </ErrorBoundary>
  );
};

export default EventPage;