import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Link, Edit2, Trash2 } from 'lucide-react';
import { useStore } from '../../../store/useStore';
import { useNotification } from '../../../hooks/useNotification';
import { Wishlist } from '../../../types/wishlist';
import WishlistActions from './WishlistActions';

interface WishlistCardProps {
  wishlist: Wishlist;
}

export default function WishlistCard({ wishlist }: WishlistCardProps) {
  const navigate = useNavigate();
  const { showSuccess } = useNotification();

  const handleClick = () => {
    navigate(`/wishlist/${wishlist.id}`);
  };

  const handleCopyLink = (e: React.MouseEvent) => {
    e.stopPropagation();
    const url = `${window.location.origin}/wishlist/${wishlist.id}`;
    navigator.clipboard.writeText(url);
    showSuccess('Link gekopieerd naar klembord');
  };

  return (
    <div
      onClick={handleClick}
      className="bg-white p-6 rounded-lg shadow-sm hover:shadow-md transition-shadow cursor-pointer"
    >
      <div className="flex justify-between items-start">
        <div className="flex-grow">
          <h3 className="text-lg font-medium text-gray-900">{wishlist.name}</h3>
          <p className="text-sm text-gray-500 mt-1">
            {wishlist.items?.length || 0} items
          </p>
        </div>
        <WishlistActions 
          wishlistId={wishlist.id}
          onCopyLink={handleCopyLink}
        />
      </div>
    </div>
  );
}