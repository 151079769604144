import React from 'react';
import { Trash2, UserPlus } from 'lucide-react';
import { useDroppable } from '@dnd-kit/core';
import DraggableParticipant from './DraggableParticipant';

interface TaskItemProps {
  task: {
    id: string;
    title: string;
    completed: boolean;
    assignedParticipants: string[];
  };
  participants: Array<{
    id: string;
    firstName: string;
    lastName: string;
  }>;
  currentUserId: string;
  isOrganizer: boolean;
  onToggle: () => void;
  onDelete?: () => void;
  onRemoveParticipant: (participantId: string) => void;
}

export default function TaskItem({ 
  task, 
  participants, 
  currentUserId,
  isOrganizer,
  onToggle, 
  onDelete,
  onRemoveParticipant
}: TaskItemProps) {
  const { setNodeRef, isOver } = useDroppable({
    id: task.id
  });

  const assignedParticipants = task.assignedParticipants
    .map(id => participants.find(p => p.id === id))
    .filter((p): p is NonNullable<typeof p> => p !== undefined);

  return (
    <div
      ref={setNodeRef}
      className={`p-4 rounded-lg border-2 transition-all ${
        isOver ? 'border-warm-olive bg-warm-olive/5' :
        task.completed ? 'border-gray-200 bg-gray-50' :
        assignedParticipants.length ? 'border-warm-olive/20 bg-white' :
        'border-dashed border-gray-300 bg-white hover:border-warm-olive/30'
      }`}
    >
      <div className="flex items-start justify-between">
        <div className="flex items-start space-x-3 flex-grow">
          <label className="relative inline-flex items-center cursor-pointer mt-1">
            <input
              type="checkbox"
              checked={task.completed}
              onChange={onToggle}
              className="sr-only peer"
            />
            <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-warm-olive/20 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-warm-olive"></div>
          </label>
          <div className="flex-grow">
            <div className="flex items-center justify-between">
              <p className={`text-gray-900 ${task.completed ? 'line-through text-gray-500' : ''}`}>
                {task.title}
              </p>
              {onDelete && (
                <button
                  onClick={onDelete}
                  className="p-1 text-gray-400 hover:text-red-600 transition-colors ml-2"
                >
                  <Trash2 className="h-5 w-5" />
                </button>
              )}
            </div>
            
            <div className="mt-3">
              {assignedParticipants.length > 0 ? (
                <div className="flex flex-wrap gap-2">
                  {assignedParticipants.map((participant) => (
                    <DraggableParticipant
                      key={participant.id}
                      participant={participant}
                      isCurrentUser={participant.id === currentUserId || isOrganizer}
                      onRemove={() => onRemoveParticipant(participant.id)}
                    />
                  ))}
                </div>
              ) : (
                <div className="flex items-center text-sm text-gray-500">
                  <UserPlus className="h-4 w-4 mr-2" />
                  <span>Sleep je naam hierheen om mee te helpen</span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}