import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../routes/constants';

export default function EmptyState() {
  const navigate = useNavigate();

  return (
    <div className="text-center py-8">
      <p className="text-gray-600 mb-4">
        Je hebt nog geen verlanglijsten. Maak er een aan om te beginnen!
      </p>
      <button
        onClick={() => navigate(ROUTES.CREATE_WISHLIST)}
        className="bg-warm-olive text-white px-6 py-2 rounded-md hover:bg-cool-olive"
      >
        Maak je eerste verlanglijst
      </button>
    </div>
  );
}