import React, { useState } from 'react';
import { X } from 'lucide-react';
import { useStore } from '../store/useStore';
import { toast } from 'react-toastify';
import { sendPasswordResetEmail } from 'firebase/auth';
import { auth } from '../config/firebase';

interface LoginModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSwitchToRegister: () => void;
  onLoginSuccess?: () => void;
}

export default function LoginModal({ isOpen, onClose, onSwitchToRegister, onLoginSuccess }: LoginModalProps) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [isResettingPassword, setIsResettingPassword] = useState(false);
  const { login, loading, error } = useStore();

  if (!isOpen) return null;

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    try {
      await login(email, password, { rememberMe });
      onClose();
      onLoginSuccess?.();
      toast.success('Succesvol ingelogd!');
    } catch (error) {
      // Error is handled by the store
    }
  };

  const handlePasswordReset = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!email) {
      toast.error('Vul je e-mailadres in om je wachtwoord te resetten');
      return;
    }

    try {
      await sendPasswordResetEmail(auth, email);
      toast.success('Controleer je e-mail om je wachtwoord te resetten');
      setIsResettingPassword(false);
    } catch (error: any) {
      toast.error('Er ging iets mis bij het versturen van de reset e-mail');
    }
  };

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="flex min-h-full items-center justify-center p-4">
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75" onClick={onClose} />
        
        <div className="relative w-full max-w-md bg-white rounded-lg shadow-xl">
          <div className="p-6">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-semibold text-gray-900">
                {isResettingPassword ? 'Wachtwoord Resetten' : 'Inloggen'}
              </h2>
              <button onClick={onClose} className="text-gray-400 hover:text-gray-500">
                <X className="h-6 w-6" />
              </button>
            </div>

            {isResettingPassword ? (
              <form onSubmit={handlePasswordReset} className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700">E-mail</label>
                  <input
                    type="email"
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="mt-1 block w-full rounded-md border-2 border-gray-300 shadow-sm focus:border-warm-olive focus:ring-warm-olive"
                  />
                </div>
                <div className="flex flex-col space-y-2">
                  <button
                    type="submit"
                    className="w-full bg-warm-olive text-white px-4 py-2 rounded-md hover:bg-cool-olive"
                  >
                    Reset Wachtwoord
                  </button>
                  <button
                    type="button"
                    onClick={() => setIsResettingPassword(false)}
                    className="w-full text-gray-600 px-4 py-2 rounded-md hover:bg-gray-50"
                  >
                    Terug naar Inloggen
                  </button>
                </div>
              </form>
            ) : (
              <form onSubmit={handleSubmit} className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700">E-mail</label>
                  <input
                    type="email"
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="mt-1 block w-full rounded-md border-2 border-gray-300 shadow-sm focus:border-warm-olive focus:ring-warm-olive"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">Wachtwoord</label>
                  <input
                    type="password"
                    required
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="mt-1 block w-full rounded-md border-2 border-gray-300 shadow-sm focus:border-warm-olive focus:ring-warm-olive"
                  />
                </div>

                <div className="flex items-center">
                  <input
                    type="checkbox"
                    id="remember-me"
                    checked={rememberMe}
                    onChange={(e) => setRememberMe(e.target.checked)}
                    className="h-4 w-4 text-warm-olive focus:ring-warm-olive border-gray-300 rounded"
                  />
                  <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-700">
                    Onthoud mij
                  </label>
                </div>

                {error && (
                  <p className="text-sm text-red-600">{error}</p>
                )}

                <button
                  type="submit"
                  disabled={loading}
                  className="w-full bg-warm-olive text-white px-4 py-2 rounded-md hover:bg-cool-olive disabled:opacity-50"
                >
                  {loading ? 'Bezig met inloggen...' : 'Inloggen'}
                </button>

                <div className="text-center space-y-2">
                  <button
                    type="button"
                    onClick={onSwitchToRegister}
                    className="text-sm text-warm-olive hover:text-cool-olive"
                  >
                    Nog geen login? Registreer
                  </button>
                  <button
                    type="button"
                    onClick={() => setIsResettingPassword(true)}
                    className="block w-full text-sm text-warm-olive hover:text-cool-olive"
                  >
                    Wachtwoord vergeten?
                  </button>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}