import React from 'react';

interface FilterButtonProps {
  label: string;
  value: string;
  isSelected: boolean;
  onClick: () => void;
}

export default function FilterButton({ 
  label, 
  value, 
  isSelected, 
  onClick 
}: FilterButtonProps) {
  const buttonClasses = isSelected
    ? 'bg-warm-olive text-white'
    : 'bg-gray-100 text-gray-700 hover:bg-gray-200';

  return (
    <button
      onClick={onClick}
      className={`px-3 py-1 rounded-full text-sm transition-colors ${buttonClasses}`}
    >
      {label}
    </button>
  );
}