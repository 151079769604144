import React, { useState, useEffect } from 'react';
import { Gift, Users } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

interface HeroProps {
  onCreateWishlist: () => void;
}

const titles = [
  { text: 'Trek Lootjes', id: 1 },
  { text: 'Organiseer Evenementen', id: 2 },
  { text: 'Maak Verlanglijstjes', id: 3 },
  { text: 'Volg jouw vrienden', id: 4 }
];

export default function Hero({ onCreateWishlist }: HeroProps) {
  const [currentTitleIndex, setCurrentTitleIndex] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTitleIndex((prevIndex) => (prevIndex + 1) % titles.length);
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  const handleCreateEvent = () => {
    navigate('/create-event');
  };

  return (
    <div className="relative overflow-hidden">
      <div 
        className="absolute inset-0 z-0"
        style={{
          backgroundImage: `url('/pattern-bg.svg')`,
          backgroundSize: 'cover',
          backgroundPosition: 'top center',
          opacity: 0.2,
          transform: 'scaleY(0.7)'
        }}
      />
      <div className="relative z-10 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-2 pb-12">
        <div className="text-center">
          <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
            <div className="h-20 sm:h-24 md:h-28 relative overflow-hidden flex items-center justify-center">
              {titles.map((title, index) => {
                const isActive = index === currentTitleIndex;
                return (
                  <span
                    key={title.id}
                    className={`absolute w-full left-0 transition-all duration-500 ${
                      isActive
                        ? 'translate-y-0 opacity-100'
                        : '-translate-y-full opacity-0'
                    }`}
                  >
                    <span className={`block ${index === 1 || index === 3 ? 'text-warm-olive' : 'text-cool-olive'}`}>
                      {title.text}
                    </span>
                  </span>
                );
              })}
            </div>
          </h1>
          <p className="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
            Maak het geven van cadeaus, het organiseren van evenementen en het maken van verlanglijstjes leuk en gemakkelijk!
          </p>
          <div className="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-6 space-y-4 sm:space-y-0 sm:space-x-4">
            <button
              onClick={handleCreateEvent}
              className="w-full sm:w-64 h-24 flex flex-col items-center justify-center px-8 border border-transparent text-base font-medium rounded-md text-white bg-warm-olive hover:bg-cool-olive transition-colors duration-200"
            >
              <Users className="h-6 w-6 mb-2" />
              <span>Gelukjes Verdelen</span>
              <span className="text-sm opacity-90">(Lootjes Trekken)</span>
            </button>
           <button
  onClick={onCreateWishlist}
  className="w-full sm:w-64 h-24 flex flex-col items-center justify-center px-8 border border-transparent text-base font-medium rounded-md text-white bg-cool-olive hover:bg-warm-olive transition-colors duration-200"
>
  <Gift className="h-8 w-8 text-white mb-2" /> {/* Cadeautje boven de tekst */}
  <span>Maak Verlanglijst</span>
</button>
          </div>
        </div>
      </div>
    </div>
  );
}