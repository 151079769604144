import { StateCreator } from 'zustand';
import { doc, collection, query, where, getDocs, setDoc, updateDoc, deleteDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../../config/firebase';
import { StoreState } from '../types';
import { Event } from '../../types/event';
import { handleError } from '../../services/errorHandling';

export interface EventSlice {
  events: Event[];
  loadEvents: () => Promise<void>;
  createEvent: (eventData: any) => Promise<string>;
  updateEvent: (eventId: string, data: Partial<Event>) => Promise<void>;
  deleteEvent: (eventId: string) => Promise<void>;
}

export const createEventSlice: StateCreator<StoreState, [], [], EventSlice> = (set, get) => ({
  events: [],

  loadEvents: async () => {
    const { currentUser } = get();
    if (!currentUser) return;

    try {
      const organizerQuery = query(
        collection(db, 'events'),
        where('organizer', '==', currentUser.id)
      );
      const participantQuery = query(
        collection(db, 'events'),
        where(`participants.${currentUser.id}.confirmed`, '==', true)
      );

      const [organizerSnapshot, participantSnapshot] = await Promise.all([
        getDocs(organizerQuery),
        getDocs(participantQuery)
      ]);

      const eventsMap = new Map();
      
      organizerSnapshot.docs.forEach(doc => {
        eventsMap.set(doc.id, { id: doc.id, ...doc.data() });
      });
      
      participantSnapshot.docs.forEach(doc => {
        if (!eventsMap.has(doc.id)) {
          eventsMap.set(doc.id, { id: doc.id, ...doc.data() });
        }
      });

      set({ events: Array.from(eventsMap.values()) });
    } catch (error) {
      console.error('Error loading events:', error);
      throw handleError(error);
    }
  },

  createEvent: async (eventData) => {
    const { currentUser } = get();
    if (!currentUser) throw new Error('Not authenticated');

    try {
      const eventId = crypto.randomUUID();
      
      // Convert participants array to object with IDs as keys
      const participantsObject = eventData.participants.reduce((acc, participant) => {
        acc[participant.id] = {
          firstName: participant.firstName,
          lastName: participant.lastName,
          confirmed: participant.id === currentUser.id, // Organizer is auto-confirmed
          email: participant.email || null,
          wishlistId: participant.wishlistId || null
        };
        return acc;
      }, {});

      const eventDoc = {
        id: eventId,
        name: eventData.name,
        date: eventData.date,
        time: eventData.time || null,
        budget: eventData.budget || 0,
        organizer: currentUser.id,
        isLootjesEvent: eventData.isLootjesEvent || false,
        registrationDeadline: eventData.registrationDeadline || null,
        maxParticipants: eventData.maxParticipants || null,
        participants: participantsObject,
        messages: [],
        lastReadTimestamps: {},
        drawnNames: {},
        tasks: [],
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp()
      };

      await setDoc(doc(db, 'events', eventId), eventDoc);
      
      const { events } = get();
      set({ events: [...events, { ...eventDoc, id: eventId }] });
      
      return eventId;
    } catch (error) {
      console.error('Create event error:', error);
      throw handleError(error);
    }
  },

  updateEvent: async (eventId, data) => {
    try {
      const eventRef = doc(db, 'events', eventId);
      await updateDoc(eventRef, {
        ...data,
        updatedAt: serverTimestamp()
      });

      const { events } = get();
      const updatedEvents = events.map(event =>
        event.id === eventId ? { ...event, ...data } : event
      );
      
      set({ events: updatedEvents });
    } catch (error) {
      throw handleError(error);
    }
  },

  deleteEvent: async (eventId) => {
    try {
      await deleteDoc(doc(db, 'events', eventId));
      
      const { events } = get();
      set({ events: events.filter(event => event.id !== eventId) });
    } catch (error) {
      throw handleError(error);
    }
  }
});