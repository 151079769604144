import { onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { auth, db } from '../config/firebase';
import { Store } from '../store/types';

export const subscribeToAuthState = (store: Store) => {
  onAuthStateChanged(auth, async (user) => {
    if (user) {
      try {
        // Get user data from Firestore
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          store.currentUser = { ...userData, id: user.uid };
          
          // Load wishlists and events
          await Promise.all([
            store.loadWishlists(),
            store.loadEvents()
          ]);
        }
      } catch (error) {
        console.error('Error loading user data:', error);
      }
    } else {
      // Clear user data and lists on logout
      store.currentUser = null;
      store.wishlists = [];
      store.events = [];
    }
  });
};