import React from 'react';
import { Routes, Route } from 'react-router-dom';
import RequireAuth from '../components/RequireAuth';
import { ROUTES } from './constants';

// Page imports
import Home from '../pages/Home';
import Dashboard from '../pages/Dashboard';
import ProductSearchPage from '../pages/ProductSearchPage';
import WishlistDetailPage from '../pages/WishlistDetailPage';
import WishlistRequestPage from '../pages/WishlistRequestPage';
import EventPage from '../pages/EventPage';
import EventWelcomePage from '../pages/EventWelcomePage';
import EventInvitesPage from '../pages/EventInvitesPage';
import ProfilePage from '../pages/ProfilePage';
import CreateEventPage from '../pages/CreateEventPage';
import SearchPage from '../pages/SearchPage';
import PrivacyPage from '../pages/PrivacyPage';
import LogoDownloadPage from '../pages/LogoDownloadPage';

export function AppRoutes() {
  return (
    <Routes>
      {/* Public Routes */}
      <Route path={ROUTES.HOME} element={<Home />} />
      <Route path={ROUTES.PRIVACY} element={<PrivacyPage />} />
      <Route path={ROUTES.SEARCH} element={<SearchPage />} />
      <Route path={ROUTES.LOGO} element={<LogoDownloadPage />} />
      <Route path={ROUTES.EVENT_WELCOME} element={<EventWelcomePage />} />
      
      {/* Protected Routes */}
      <Route path={ROUTES.CREATE_WISHLIST} element={
        <RequireAuth>
          <ProductSearchPage />
        </RequireAuth>
      } />
      <Route path={ROUTES.DASHBOARD} element={
        <RequireAuth>
          <Dashboard />
        </RequireAuth>
      } />
      <Route path={ROUTES.WISHLIST} element={
        <RequireAuth>
          <WishlistDetailPage />
        </RequireAuth>
      } />
      <Route path={ROUTES.EVENT} element={
        <RequireAuth>
          <EventPage />
        </RequireAuth>
      } />
      <Route path={ROUTES.EVENT_INVITES} element={
        <RequireAuth>
          <EventInvitesPage />
        </RequireAuth>
      } />
      <Route path={ROUTES.EVENT_REQUEST_WISHLIST} element={
        <RequireAuth>
          <WishlistRequestPage />
        </RequireAuth>
      } />
      <Route path={ROUTES.PROFILE} element={
        <RequireAuth>
          <ProfilePage />
        </RequireAuth>
      } />
      <Route path={ROUTES.CREATE_EVENT} element={
        <RequireAuth>
          <CreateEventPage />
        </RequireAuth>
      } />
    </Routes>
  );
}

// Export the component as both default and named export
export default AppRoutes;