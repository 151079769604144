import React from 'react';
import { AnimatePresence } from 'framer-motion';
import { useNotificationStore } from '../../utils/notifications';
import NotificationItem from './NotificationItem';

export default function NotificationContainer() {
  const { notifications, removeNotification } = useNotificationStore();

  return (
    <div className="fixed top-4 right-4 z-50 space-y-2">
      <AnimatePresence>
        {notifications.map((notification) => (
          <NotificationItem
            key={notification.id}
            {...notification}
            onClose={removeNotification}
          />
        ))}
      </AnimatePresence>
    </div>
  );
}