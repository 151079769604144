import React from 'react';
import { Gift, Share2, Heart, Users, CalendarPlus, Bell } from 'lucide-react';

export default function HowItWorks() {
  return (
    <div className="py-24 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* General Introduction */}
        <div className="text-center">
          <h2 className="text-3xl font-bold text-gray-900 sm:text-4xl">
            Hoe werkt Wish2Share?
          </h2>
          <p className="mt-4 text-xl text-gray-600">
            In drie eenvoudige stappen naar het perfecte cadeau
          </p>
        </div>

        <div className="mt-20">
          <div className="grid grid-cols-1 gap-12 lg:grid-cols-3">
            <div className="text-center">
              <div className="flex justify-center">
                <div className="relative">
                  <div className="absolute -inset-2">
                    <div className="w-full h-full mx-auto rotate-6 bg-warm-olive opacity-20 blur-lg"></div>
                  </div>
                  <div className="relative">
                    <div className="inline-flex items-center justify-center w-16 h-16 rounded-full bg-warm-olive text-white">
                      <Gift className="w-8 h-8" />
                    </div>
                  </div>
                </div>
              </div>
              <h3 className="mt-8 text-xl font-semibold text-gray-900">
                Maak een evenement of verlanglijstje
              </h3>
              <p className="mt-4 text-gray-600">
                Begin met het aanmaken van een evenement voor lootjes trekken of maak je persoonlijke verlanglijst.
              </p>
            </div>

            <div className="text-center">
              <div className="flex justify-center">
                <div className="relative">
                  <div className="absolute -inset-2">
                    <div className="w-full h-full mx-auto rotate-6 bg-warm-olive opacity-20 blur-lg"></div>
                  </div>
                  <div className="relative">
                    <div className="inline-flex items-center justify-center w-16 h-16 rounded-full bg-warm-olive text-white">
                      <Share2 className="w-8 h-8" />
                    </div>
                  </div>
                </div>
              </div>
              <h3 className="mt-8 text-xl font-semibold text-gray-900">
                Deel met familie en vrienden
              </h3>
              <p className="mt-4 text-gray-600">
                Nodig anderen uit om deel te nemen aan je evenement of om je verlanglijst te bekijken.
              </p>
            </div>

            <div className="text-center">
              <div className="flex justify-center">
                <div className="relative">
                  <div className="absolute -inset-2">
                    <div className="w-full h-full mx-auto rotate-6 bg-warm-olive opacity-20 blur-lg"></div>
                  </div>
                  <div className="relative">
                    <div className="inline-flex items-center justify-center w-16 h-16 rounded-full bg-warm-olive text-white">
                      <Heart className="w-8 h-8" />
                    </div>
                  </div>
                </div>
              </div>
              <h3 className="mt-8 text-xl font-semibold text-gray-900">
                Ontvang jouw favoriete cadeautjes
              </h3>
              <p className="mt-4 text-gray-600">
                Geniet van het uitwisselen van persoonlijke en betekenisvolle cadeaus met je dierbaren.
              </p>
            </div>
          </div>
        </div>

        {/* Detailed Feature Guides */}
        <div className="mt-32 space-y-32">
          {/* Verlanglijsten Guide */}
          <section id="wishlists" className="scroll-mt-16">
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
              <div>
                <div className="flex items-center space-x-4 mb-6">
                  <div className="p-3 bg-warm-olive rounded-lg text-white">
                    <Gift className="w-6 h-6" />
                  </div>
                  <h3 className="text-2xl font-bold text-gray-900">Maak Verlanglijsten</h3>
                </div>
                <div className="space-y-4">
                  <p className="text-gray-600">Zo maak je een verlanglijst:</p>
                  <ol className="list-decimal list-inside space-y-3 text-gray-600">
                    <li>Log in of maak een account aan</li>
                    <li>Klik op 'Maak Verlanglijst' in je dashboard</li>
                    <li>Geef je lijst een naam (bijvoorbeeld "Verjaardag 2024")</li>
                    <li>Voeg items toe met titel, beschrijving, link en prijs</li>
                    <li>Deel je lijst met familie en vrienden</li>
                  </ol>
                </div>
              </div>
              <div>
                <img
                  src="https://images.unsplash.com/photo-1513201099705-a9746e1e201f?w=800"
                  alt="Verlanglijst maken"
                  className="rounded-lg shadow-lg"
                />
              </div>
            </div>
          </section>

          {/* Lootjes Trekken Guide */}
          <section id="drawing" className="scroll-mt-16">
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
              <div className="order-2 lg:order-1">
                <img
                  src="https://images.unsplash.com/photo-1513885535751-8b9238bd345a?w=800"
                  alt="Lootjes trekken"
                  className="rounded-lg shadow-lg"
                />
              </div>
              <div className="order-1 lg:order-2">
                <div className="flex items-center space-x-4 mb-6">
                  <div className="p-3 bg-warm-olive rounded-lg text-white">
                    <Users className="w-6 h-6" />
                  </div>
                  <h3 className="text-2xl font-bold text-gray-900">Gelukjes Verdelen</h3>
                </div>
                <div className="space-y-4">
                  <p className="text-gray-600">Zo organiseer je lootjes trekken:</p>
                  <ol className="list-decimal list-inside space-y-3 text-gray-600">
                    <li>Maak een nieuw evenement aan</li>
                    <li>Stel de datum en het budget per persoon in</li>
                    <li>Voeg alle deelnemers toe</li>
                    <li>Verstuur de uitnodigingen</li>
                    <li>Trek de lootjes zodra iedereen heeft bevestigd</li>
                  </ol>
                </div>
              </div>
            </div>
          </section>

          {/* Evenement Organiseren Guide */}
          <section id="events" className="scroll-mt-16">
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
              <div>
                <div className="flex items-center space-x-4 mb-6">
                  <div className="p-3 bg-warm-olive rounded-lg text-white">
                    <CalendarPlus className="w-6 h-6" />
                  </div>
                  <h3 className="text-2xl font-bold text-gray-900">Organiseer een Evenement</h3>
                </div>
                <div className="space-y-4">
                  <p className="text-gray-600">Zo organiseer je een evenement:</p>
                  <ol className="list-decimal list-inside space-y-3 text-gray-600">
                    <li>Kies een datum en locatie</li>
                    <li>Stel het budget en eventuele thema's in</li>
                    <li>Nodig deelnemers uit via e-mail of deelbare link</li>
                    <li>Houd de voortgang bij in het dashboard</li>
                    <li>Communiceer met deelnemers via de groepschat</li>
                  </ol>
                </div>
              </div>
              <div>
                <img
                  src="https://images.unsplash.com/photo-1517048676732-d65bc937f952?w=800"
                  alt="Evenement organiseren"
                  className="rounded-lg shadow-lg"
                />
              </div>
            </div>
          </section>

          {/* Volgen Guide */}
          <section id="subscriptions" className="scroll-mt-16">
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
              <div className="order-2 lg:order-1">
                <img
                  src="https://images.unsplash.com/photo-1521791136064-7986c2920216?w=800"
                  alt="Vrienden volgen"
                  className="rounded-lg shadow-lg"
                />
              </div>
              <div className="order-1 lg:order-2">
                <div className="flex items-center space-x-4 mb-6">
                  <div className="p-3 bg-warm-olive rounded-lg text-white">
                    <Bell className="w-6 h-6" />
                  </div>
                  <h3 className="text-2xl font-bold text-gray-900">Volg jouw vrienden</h3>
                </div>
                <div className="space-y-4">
                  <p className="text-gray-600">Zo volg je verlanglijsten van vrienden:</p>
                  <ol className="list-decimal list-inside space-y-3 text-gray-600">
                    <li>Zoek vrienden op naam of e-mailadres</li>
                    <li>Bekijk hun openbare verlanglijsten</li>
                    <li>Klik op 'Volgen' bij de lijsten die je interesseren</li>
                    <li>Ontvang meldingen bij updates</li>
                    <li>Bekijk alle gevolgde lijsten in je dashboard</li>
                  </ol>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}