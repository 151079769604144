import { useCallback } from 'react';
import { showSuccess, showError, showInfo, showWarning } from '../utils/notifications';

export function useNotification() {
  const success = useCallback((message: string) => {
    showSuccess(message);
  }, []);

  const error = useCallback((message: string) => {
    showError(message);
  }, []);

  const info = useCallback((message: string) => {
    showInfo(message);
  }, []);

  const warning = useCallback((message: string) => {
    showWarning(message);
  }, []);

  return {
    showSuccess: success,
    showError: error,
    showInfo: info,
    showWarning: warning
  };
}