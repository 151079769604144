import React from 'react';
import { Search } from 'lucide-react';

export default function EmptyState() {
  return (
    <div className="text-center py-12">
      <Search className="h-12 w-12 text-gray-400 mx-auto mb-4" />
      <p className="text-gray-500">
        Zoek naar producten om toe te voegen aan je verlanglijst
      </p>
    </div>
  );
}