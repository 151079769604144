import { initializeApp } from 'firebase/app';
import { getAuth, browserLocalPersistence, setPersistence } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';

const firebaseConfig = {
  apiKey: "AIzaSyAMWa-RQ-1O6rraGGngMZUNKpfvK9FHO-M",
  authDomain: "wish2share4u.firebaseapp.com", 
  projectId: "wish2share4u",
  storageBucket: "wish2share4u.firebasestorage.app",
  messagingSenderId: "1062216108615",
  appId: "1:1062216108615:web:82bbac19eb754643bb3f62",
  measurementId: "G-BPETKRG0XW"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Auth with local persistence
const auth = getAuth(app);
setPersistence(auth, browserLocalPersistence);

// Initialize Firestore
const db = getFirestore(app);

// Initialize Functions
const functions = getFunctions(app);

export { app, auth, db, functions };